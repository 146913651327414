/* eslint-disable react/jsx-props-no-spreading */
import DOMPurify from "dompurify";
import _ from "lodash";
import React, { FC, ReactElement } from "react";
import { Alert } from "react-bootstrap";
import { ComponentProps, NotImplementedError } from "../../models";
import ComponentManager from "../../utils/ComponentManager";

interface OptionalRequestStateProps {
  request?: unknown;
  setRequest?: unknown;
}

const Component: FC<ComponentProps> = ({
  tag,
  props = {},
  content,
}: ComponentProps): ReactElement => {
  if (!tag) {
    return <></>;
  }

  if (ComponentManager.isHtmlElementType(tag) && content) {
    const HtmlTag = tag as keyof JSX.IntrinsicElements;
    const { request, setRequest, ...propsWithoutRequestState } =
      props as OptionalRequestStateProps;

    if (typeof content === "string") {
      return (
        <HtmlTag
          {...propsWithoutRequestState}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(content),
          }}
        />
      );
    }

    return (
      <HtmlTag {...propsWithoutRequestState}>
        {(Array.isArray(content) ? content : [content]).map((childProps) => (
          <Component
            key={_.uniqueId()}
            {...childProps}
            props={{ ...(childProps?.props || {}), request, setRequest }}
          />
        ))}
      </HtmlTag>
    );
  }

  try {
    const [Tag, isEmoryComponent] = ComponentManager.getComponent(tag);

    if (isEmoryComponent) {
      return (
        <Tag
          {...((props as Record<string, unknown>) || {})}
          content={content}
        />
      );
    }

    const { request, setRequest, ...tagProps } =
      props as OptionalRequestStateProps;

    if (content) {
      return <Tag {...tagProps}>{content as string}</Tag>;
    }

    return <Tag {...tagProps} />;
  } catch (error) {
    let errorMessage = `${error}`;
    if (error instanceof NotImplementedError) {
      errorMessage = error.message;
    }
    return (
      <Alert variant="danger">
        Failed to render Component: tag={tag}, cause={errorMessage}
      </Alert>
    );
  }
};

Component.defaultProps = {
  props: {},
  content: undefined,
};

export default Component;
