import AppPath from "../../../components/App/models/AppPath";

class QTMPath extends AppPath {
  public static readonly QTM = new AppPath({
    id: "qtm",
    label: "QTM Student Onboarding",
    path: "/qtm",
  });
}
export default QTMPath;
