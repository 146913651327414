import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  CSSProperties,
  FC,
  MouseEvent,
  MouseEventHandler,
  ReactElement,
} from "react";
import { Button, ButtonProps, Col, Row } from "react-bootstrap";
import ReactModal from "react-modal";
import { v4 as uuid } from "uuid";

export type ModalProps = Omit<ReactModal.Props, "isOpen"> & {
  show: boolean;
  title?: string | ReactElement;
  body: string | ReactElement;
  cancelButtonProps?: ButtonProps;
  cancelButtonText?: string;
  submitButtonProps?: ButtonProps;
  submitButtonText?: string;
  onCancel: MouseEventHandler<HTMLButtonElement>;
  onSubmit: MouseEventHandler<HTMLButtonElement>;
  modalContentStyles?: CSSProperties;
  hideModalTitleCloseButton?: boolean;
  hideCancelButton?: boolean;
  hideAllButtons?: boolean;
  otherButtonsProps?: (ButtonProps & { buttonText: string })[];
  disabled?: boolean;
};

const Modal: FC<ModalProps> = ({
  show,
  title,
  body,
  cancelButtonProps,
  cancelButtonText,
  submitButtonProps,
  submitButtonText,
  onCancel,
  onSubmit,
  modalContentStyles,
  hideModalTitleCloseButton,
  hideCancelButton,
  hideAllButtons,
  otherButtonsProps,
  ...modalProps
}: ModalProps) => {
  const handleCancel = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    onCancel(event);
  };

  const handleSubmit = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    onSubmit(event);
  };

  return (
    <ReactModal
      style={{
        content: {
          width: "80%",
          maxHeight: "80%",
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          ...modalContentStyles,
        },
      }}
      isOpen={show}
      onRequestClose={onCancel}
      shouldCloseOnOverlayClick={false}
      {...modalProps}
    >
      {title && (
        <Row className="modal-title pb-2 border border-top-0 border-start-0 border-end-0">
          <Col className="d-flex justify-content-between">
            <div className="modal-title-text">
              {React.isValidElement(title) ? (
                <>{title}</>
              ) : (
                <h3>
                  <>{title}</>
                </h3>
              )}
            </div>
            {!(hideCancelButton || hideModalTitleCloseButton) && (
              <div className="modal-title-close-button">
                <Button variant="link" onClick={handleCancel}>
                  <FontAwesomeIcon icon={faTimes} size="lg" />
                </Button>
              </div>
            )}
          </Col>
        </Row>
      )}
      <Row
        className={`mt-3 border border-top-0 border-start-0 border-end-0 ${
          hideAllButtons ? "border-bottom-0" : "pb-4"
        }`}
      >
        <Col>
          <>{body}</>
        </Col>
      </Row>
      {!hideAllButtons && (
        <Row className="mt-3">
          <Col className="d-flex justify-content-center">
            {!hideCancelButton && (
              <Button
                variant="light"
                className="me-2"
                {...cancelButtonProps}
                onClick={handleCancel}
              >
                {cancelButtonText}
              </Button>
            )}
            {otherButtonsProps?.map(({ buttonText, ...otherButtonProps }) => (
              <Button key={uuid()} {...otherButtonProps}>
                {buttonText}
              </Button>
            ))}
            <Button
              variant="primary"
              {...submitButtonProps}
              onClick={handleSubmit}
            >
              {submitButtonText}
            </Button>
          </Col>
        </Row>
      )}
    </ReactModal>
  );
};

Modal.defaultProps = {
  title: undefined,
  cancelButtonProps: {},
  cancelButtonText: "Cancel",
  submitButtonProps: {},
  submitButtonText: "Submit",
  modalContentStyles: {},
  hideModalTitleCloseButton: false,
  hideCancelButton: false,
  hideAllButtons: false,
  otherButtonsProps: undefined,
  disabled: false,
};

export default Modal;
