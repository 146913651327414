import axios from "axios";
import { AxiosCacheInstance, setupCache } from "axios-cache-interceptor";
import { handleShibbolethTimeoutError } from "../../modules/shibboleth/utils";

export { handleShibbolethTimeoutError };

export const getAxiosInstance = (
  configureAxiosInstance: (
    axiosInstance: AxiosCacheInstance
  ) => AxiosCacheInstance
): AxiosCacheInstance => {
  const axiosInstance = setupCache(
    axios.create({
      responseType: "json",
      maxRedirects: 0,
    }),
    {
      interpretHeader: true,
      ttl: 5 * 60 * 1000, // 5 minutes cache
    }
  );

  return configureAxiosInstance(axiosInstance);
};

export const getDefaultAxiosInstance = (): AxiosCacheInstance => {
  return getAxiosInstance((axiosInstance) => {
    axiosInstance.interceptors.request.use(
      (config) => {
        // Do something before request is sent
        return config;
      },
      (error) => {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    axiosInstance.interceptors.response.use(
      (response) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        handleShibbolethTimeoutError(error);
        return Promise.reject(error);
      }
    );

    return axiosInstance;
  });
};

const AxiosService = getDefaultAxiosInstance();

export default AxiosService;
