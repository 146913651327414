/* eslint-disable react/jsx-props-no-spreading */
import _ from "lodash";
import {
  Dispatch,
  FC,
  FormEvent,
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Form as BootstrapForm,
  FormProps as BootstrapFormProps,
} from "react-bootstrap";
import { ComponentProps } from "../../models";
import FormRule from "../../models/salesforce/FormRule";
import {
  getTargetId,
  parseDependentFieldNamesFromAllFormRules,
  processFormRules,
} from "../../utils/FormRuleUtil";
import { getModifiedFieldNames } from "../../utils/ObjectsUtil";
import { renderContent } from "../Component/ComponentUtil";
import { FormContext, FormContextProps } from "./models/FormContext";
import { FormRequest, RequestStateProps } from "./models/FormRequest";
import "./styles.scss";

export const getRequestByKey = (key: string): FormRequest => {
  const requestJson = localStorage.getItem(key);
  if (requestJson) {
    return JSON.parse(requestJson);
  }
  return {};
};

export const setRequestByKey = (key: string, request: FormRequest): void => {
  localStorage.setItem(key, JSON.stringify(request));
};

export const resetRequestByKey = (key: string): void => {
  localStorage.removeItem(key);
};

export type FormProps = BootstrapFormProps &
  Omit<RequestStateProps, "setRequest"> &
  Pick<
    FormContextProps,
    | "validateFields"
    | "alwaysValidateFields"
    | "isPrintMode"
    | "optionsByFieldNameMap"
  > & {
    setRequest?: Dispatch<SetStateAction<FormRequest>>;
    requestKey?: string;
    onSetRequestByKey?: (
      previousRequest: FormRequest,
      updatedRequest: FormRequest
    ) => FormRequest;
    content: ComponentProps | undefined;
    disabled?: boolean;
    buttons?: ReactElement;
    onSubmitRequest?: (request: FormRequest) => void;
    onFormContentChange?: (newFormContent: ComponentProps | undefined) => void;
  };

const Form: FC<FormProps> = ({
  content,
  initialRequest: initialRequestProp,
  setRequest,
  requestKey,
  onSetRequestByKey,
  onSubmit,
  onSubmitRequest,
  buttons,
  disabled,
  onFormContentChange = () => {},
  validateFields,
  alwaysValidateFields,
  isPrintMode,
  optionsByFieldNameMap,
}: FormProps): ReactElement => {
  const [formContent, setFormContent] = useState<ComponentProps>();
  const { props } = formContent || {};
  const [initialRequest, setInitialRequest] = useState<FormRequest>({});

  const formRules = useMemo(() => {
    return (formContent?.salesforceObject?.formRules as FormRule[]) || [];
  }, [formContent]);

  const children = useMemo(() => {
    return renderContent(
      formContent?.content as ComponentProps | ComponentProps[] | string
    );
  }, [formContent?.content]);

  const formRulesByTargetId: Record<string, FormRule[]> = useMemo(() => {
    if (_.isArray(formRules)) {
      return _.groupBy(formRules, getTargetId);
    }
    return {};
  }, [formRules]);

  const dependentFieldNamesByTargetId: Record<string, string[]> =
    useMemo(() => {
      return _.mapValues(
        formRulesByTargetId,
        parseDependentFieldNamesFromAllFormRules
      );
    }, [formRulesByTargetId]);

  const buildSetRequestToProcessFormRules = useCallback(
    (previousRequest: FormRequest) => {
      return (getUpdatedRequest: SetStateAction<FormRequest>) => {
        let updatedRequest;
        if (_.isFunction(getUpdatedRequest)) {
          updatedRequest = getUpdatedRequest(previousRequest);
        } else {
          updatedRequest = getUpdatedRequest;
        }
        setInitialRequest(updatedRequest);
        if (requestKey) {
          if (onSetRequestByKey) {
            updatedRequest = onSetRequestByKey(previousRequest, updatedRequest);
          }
          setRequestByKey(requestKey, updatedRequest);
        }
      };
    },
    [onSetRequestByKey, requestKey]
  );

  const reprocessFormRules = useCallback(
    (previousRequest: FormRequest, updatedRequest: FormRequest) => {
      if (formRules.length === 0) {
        return;
      }
      const modifiedFieldNames = getModifiedFieldNames(
        previousRequest,
        updatedRequest
      );
      const modifiedTargetIds = _.keys(dependentFieldNamesByTargetId).filter(
        (targetId) =>
          _.intersection(
            dependentFieldNamesByTargetId[targetId],
            modifiedFieldNames
          ).length > 0 ||
          (dependentFieldNamesByTargetId[targetId] ?? []).some(
            (dependentFieldName) =>
              modifiedFieldNames.some((modifiedFieldName) =>
                dependentFieldName.startsWith(`${modifiedFieldName}.`)
              )
          )
      );
      if (modifiedTargetIds.length > 0) {
        setFormContent((previousFormContent) => {
          return processFormRules(
            previousFormContent,
            formRules,
            updatedRequest,
            buildSetRequestToProcessFormRules(updatedRequest),
            modifiedTargetIds,
            validateFields
          );
        });
      }
    },
    [
      buildSetRequestToProcessFormRules,
      dependentFieldNamesByTargetId,
      formRules,
      validateFields,
    ]
  );

  const setFormRequest: Dispatch<SetStateAction<FormRequest>> = useMemo(() => {
    if (setRequest) {
      return setRequest;
    }
    if (requestKey) {
      return (getUpdatedRequest: SetStateAction<FormRequest>): void => {
        const previousRequest = getRequestByKey(requestKey);
        let updatedRequest: FormRequest;
        if (_.isFunction(getUpdatedRequest)) {
          updatedRequest = getUpdatedRequest(previousRequest);
        } else {
          updatedRequest = getUpdatedRequest;
        }
        if (onSetRequestByKey) {
          updatedRequest = onSetRequestByKey(previousRequest, updatedRequest);
        }
        setRequestByKey(requestKey, updatedRequest);
        reprocessFormRules(previousRequest, updatedRequest);
      };
    }
    throw new Error(
      "Expected either `setRequest` or `requestKey and onSetRequestKey` to be defined"
    );
  }, [onSetRequestByKey, reprocessFormRules, requestKey, setRequest]);

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      event.stopPropagation();
      if (onSubmit) {
        onSubmit(event);
      }
      if (requestKey && onSubmitRequest) {
        onSubmitRequest(getRequestByKey(requestKey));
      }
    },
    [onSubmit, onSubmitRequest, requestKey]
  );

  useEffect(() => {
    setInitialRequest(initialRequestProp);
    setFormContent(
      processFormRules(
        content,
        formRules,
        initialRequestProp,
        buildSetRequestToProcessFormRules(initialRequestProp),
        []
      )
    );
  }, [
    buildSetRequestToProcessFormRules,
    content,
    formRules,
    initialRequestProp,
  ]);

  useEffect(() => {
    onFormContentChange(formContent);
  }, [formContent, onFormContentChange]);

  const formContext: FormContextProps = useMemo(
    () => ({
      initialRequest,
      getRequest: () => getRequestByKey(requestKey ?? ""),
      setRequest: setFormRequest,
      disabled: !!disabled,
      validateFields,
      alwaysValidateFields,
      isPrintMode,
      optionsByFieldNameMap,
    }),
    [
      alwaysValidateFields,
      disabled,
      initialRequest,
      isPrintMode,
      optionsByFieldNameMap,
      requestKey,
      setFormRequest,
      validateFields,
    ]
  );

  return (
    <div className="form">
      <FormContext.Provider value={formContext}>
        <BootstrapForm
          {...((props as Record<string, unknown>) || {})}
          onSubmit={handleSubmit}
        >
          <fieldset disabled={disabled}>
            <>
              {children}
              {buttons}
            </>
          </fieldset>
        </BootstrapForm>
      </FormContext.Provider>
    </div>
  );
};

Form.defaultProps = {
  disabled: false,
  setRequest: undefined,
  requestKey: undefined,
  onSetRequestByKey: undefined,
  buttons: undefined,
  onSubmitRequest: undefined,
  onFormContentChange: () => {},
};

export default Form;
