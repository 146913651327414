import { FC } from "react";
import { NotImplementedError } from "../models";

class ComponentManager {
  private readonly componentCache = new Map();

  private readonly htmlElementTypes = [
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "div",
    "p",
    "span",
    "label",
  ];

  isHtmlElementType(tag: string): boolean {
    return this.htmlElementTypes.includes(tag);
  }

  addHtmlElementType(type: string) {
    if (type) {
      this.htmlElementTypes.push(type);
    }
  }

  getComponent(tag: string): [FC<{ content?: unknown }>, boolean] {
    const entry = this.componentCache.get(tag);
    if (entry) {
      return entry;
    }
    throw new NotImplementedError(
      `Undefined component for tag: ${tag}. Make sure to register your component in the ComponentManager.`
    );
  }

  addComponent(tag: string, component: unknown, isEmoryComponent: boolean) {
    this.componentCache.set(tag, [component, isEmoryComponent]);
  }

  addComponents(
    componentMap: Record<string, unknown>,
    isEmoryComponent: boolean
  ) {
    Object.keys(componentMap).forEach((tag) =>
      this.addComponent(tag, componentMap[tag], isEmoryComponent)
    );
  }
}

const INSTANCE = new ComponentManager();

export default INSTANCE;
