import { AxiosResponse } from "axios";
import { useEffect, useMemo, useState } from "react";
import { MyEmoryContextProps } from "../../models/MyEmoryContext";
import Roles from "../../models/salesforce/Roles";
import UserAccess from "../../models/salesforce/UserAccess";
import MyEmoryService from "../../services/MyEmoryService";
import useLoading from "../useLoading";
import useSafeAsync from "../useSafeAsync";

const useMyEmory = (
  ppid: string | undefined,
  emoryApplicationName?: string
): MyEmoryContextProps => {
  const loading = useLoading();
  const safeAsync = useSafeAsync();
  const [userAccess, setUserAccess] = useState<UserAccess>({});

  useEffect(() => {
    if (ppid) {
      loading(
        safeAsync(
          MyEmoryService.getUserAccessByPpid(ppid, emoryApplicationName)
        ).then((response) => {
          const userAccessResponse = response as AxiosResponse<UserAccess>;
          setUserAccess(userAccessResponse.data ?? {});
        })
      );
    }
  }, [safeAsync, ppid, loading, emoryApplicationName]);

  const roleNames = useMemo<MyEmoryContextProps["roleNames"]>(() => {
    return [
      ...(userAccess?.featureAccesses ?? []).map(({ role }) => role?.name),
      (userAccess?.roles ?? [])
        .map(({ name }) => name)
        .find((roleName) => Roles.ITAdmin === roleName),
    ].filter((roleName) => !!roleName) as MyEmoryContextProps["roleNames"];
  }, [userAccess?.featureAccesses, userAccess?.roles]);

  return {
    ...userAccess,
    roles: userAccess?.roles ?? [],
    roleNames,
  };
};

export default useMyEmory;
