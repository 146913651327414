import _ from "lodash";
import {
  evaluateChecklistContains,
  evaluateChecklistNotContains,
  evaluateEquals,
  evaluateIsEmpty,
  evaluateIsFalsy,
  evaluateIsTruthy,
  evaluateMultiselectContains,
  evaluateMultiselectNotContains,
  evaluateNotEquals,
  FormRequest,
  RequestValueComparisonFunction,
} from "../../components/Form/models/FormRequest";

type FormRuleOperationType = "function" | "operator";

class FormRuleOperation {
  private static ALL_VALUES: FormRuleOperation[] = [];

  public static readonly EQUALS = new FormRuleOperation(
    "operator",
    evaluateEquals,
    "",
    "",
    "=",
    2
  );

  public static readonly NOT_EQUALS = new FormRuleOperation(
    "operator",
    evaluateNotEquals,
    "",
    "",
    "<>",
    2
  );

  public static readonly MULTISELECT_CONTAINS = new FormRuleOperation(
    "function",
    evaluateMultiselectContains,
    "MULTISELECT_CONTAINS(",
    ")",
    ",",
    2
  );

  public static readonly MULTISELECT_NOT_CONTAINS = new FormRuleOperation(
    "function",
    evaluateMultiselectNotContains,
    "MULTISELECT_NOT_CONTAINS(",
    ")",
    ",",
    2
  );

  public static readonly IS_TRUTHY = new FormRuleOperation(
    "function",
    evaluateIsTruthy,
    "IS_TRUTHY(",
    ")"
  );

  public static readonly IS_FALSY = new FormRuleOperation(
    "function",
    evaluateIsFalsy,
    "IS_FALSY(",
    ")"
  );

  public static readonly CHECKLIST_CONTAINS = new FormRuleOperation(
    "function",
    evaluateChecklistContains,
    "CHECKLIST_CONTAINS(",
    ")",
    ",",
    2
  );

  public static readonly CHECKLIST_NOT_CONTAINS = new FormRuleOperation(
    "function",
    evaluateChecklistNotContains,
    "CHECKLIST_NOT_CONTAINS(",
    ")",
    ",",
    2
  );

  public static readonly IS_EMPTY = new FormRuleOperation(
    "function",
    evaluateIsEmpty,
    "IS_EMPTY(",
    ")"
  );

  constructor(
    public readonly type: FormRuleOperationType,
    public readonly evaluateFunction: RequestValueComparisonFunction,
    public readonly prefix: string,
    public readonly suffix: string,
    public readonly delimiter: string | null = null,
    public readonly numArgs = NaN
  ) {
    FormRuleOperation.ALL_VALUES.push(this);
  }

  public static values(): FormRuleOperation[] {
    return FormRuleOperation.ALL_VALUES;
  }

  public isMatch(ruleExpression: string): boolean {
    return (
      ruleExpression.startsWith(this.prefix) &&
      (this.delimiter == null || ruleExpression.includes(this.delimiter)) &&
      ruleExpression.endsWith(this.suffix)
    );
  }

  public evaluate(ruleExpression: string, request: FormRequest): boolean {
    const [fieldName, ...fieldValues] = this.parse(ruleExpression);
    return this.evaluateFunction(_.get(request, fieldName), fieldValues);
  }

  public parseDependentFieldNames(ruleExpression: string): string[] {
    const [fieldName] = this.parse(ruleExpression);
    return [fieldName];
  }

  private parse(ruleExpression: string): string[] {
    const rulePartsStr = ruleExpression.substring(
      this.prefix.length,
      ruleExpression.length - this.suffix.length
    );
    let ruleParts;
    if (this.delimiter) {
      ruleParts = rulePartsStr.split(this.delimiter, this.numArgs);
    } else {
      ruleParts = [rulePartsStr];
    }
    ruleParts = ruleParts.map((x) => x.trim());
    return ruleParts.map((rulePart, index) => {
      if (index === 0) {
        return rulePart;
      }
      return JSON.parse(rulePart);
    });
  }
}

export default FormRuleOperation;
