import {
  AxiosCacheInstance,
  CacheAxiosResponse,
} from "axios-cache-interceptor";
import { useMemo } from "react";
import UserAccess from "../../models/salesforce/UserAccess";
import UserRole from "../../models/salesforce/UserRole";
import BaseService from "../BaseService";

class MyEmoryService extends BaseService {
  private static readonly BASE_PATH = "/api/myemory/v1";

  constructor(axiosInstance: AxiosCacheInstance) {
    super(axiosInstance, MyEmoryService.BASE_PATH);
  }

  public getUserAccessByPpid(
    ppid: string,
    emoryApplicationName?: string
  ): Promise<CacheAxiosResponse<UserAccess>> {
    return this.get<UserAccess>({
      url: this.getPath(`/user-accesses/${ppid}`),
      config: { params: { emoryApplicationName } },
    });
  }

  public getAllUserRoles(): Promise<CacheAxiosResponse<UserRole[]>> {
    return super.get<UserRole[]>({ url: this.getPath("/user-roles") });
  }

  /**
   * @deprecated Use instantiated method
   */
  public static getUserAccessByPpid(
    ppid: string,
    emoryApplicationName?: string
  ): Promise<CacheAxiosResponse<UserAccess>> {
    return super.get<UserAccess>({
      url: `${this.BASE_PATH}/user-accesses/${ppid}`,
      config: { params: { emoryApplicationName } },
    });
  }

  /**
   * @deprecated Use instantiated method
   */
  public static getAllUserRoles(): Promise<CacheAxiosResponse<UserRole[]>> {
    return super.get<UserRole[]>({ url: `${this.BASE_PATH}/user-roles` });
  }
}

export default MyEmoryService;

export const useMyEmoryService = (
  axiosInstance: AxiosCacheInstance
): MyEmoryService => {
  return useMemo(() => new MyEmoryService(axiosInstance), [axiosInstance]);
};
