import { FC, ReactElement } from "react";
import { Container } from "react-bootstrap";
import ManagedContent from "../../../shared/src/components/ManagedContent";
import FeatureName from "../../../../components/App/models/FeatureName";
import ManagedContentName from "../../models/ManagedContentName";

const RCRASuccessPage: FC = (): ReactElement => {
  return (
    <div>
      <Container>
        <ManagedContent
          name={ManagedContentName.RCRAMinorsSuccess}
          featureName={FeatureName.RCRAMinors}
        />
      </Container>
    </div>
  );
};

export default RCRASuccessPage;
