import { createContext } from "react";

export type ManagedContentContextProps = {
  isContentManager: boolean;
  editable: boolean;
};

export const ManagedContentContext = createContext<ManagedContentContextProps>({
  isContentManager: false,
  editable: false,
});
