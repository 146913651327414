import FormSalesforceObject from "../../../models/salesforce/FormSalesforceObject";
import SalesforceResponse from "../../../models/salesforce/SalesforceResponse";
import BaseService from "../../BaseService";

abstract class FormSalesforceObjectService extends BaseService {
  protected static readonly BASE_PATH = "/api/forms/v0";

  protected static async findAll<T = FormSalesforceObject>(
    objectName: string,
    depth: number | undefined = undefined
  ): Promise<T[]> {
    const params: Record<string, string | number> = {};
    if (depth !== undefined) {
      params.depth = depth;
    }
    const response = await super.get<T[]>({
      url: `${this.BASE_PATH}/${objectName}`,
      config: { params },
    });
    return response?.data || [];
  }

  protected static async findAllById<T = FormSalesforceObject>(
    objectName: string,
    ids: string[],
    depth: number | undefined = undefined
  ): Promise<T[]> {
    const params: Record<string, string | number> = {};
    if (depth !== undefined) {
      params.depth = depth;
    }
    const response = await super.get<T[]>({
      url: `${this.BASE_PATH}/${objectName}/${ids.join(",")}`,
      config: { params },
    });
    return response?.data || [];
  }

  protected static async findById<T = FormSalesforceObject>(
    objectName: string,
    id: string,
    depth: number | undefined = undefined
  ): Promise<T> {
    const response = await this.findAllById<T>(objectName, [id], depth);
    return response[0];
  }

  protected static async save<T = FormSalesforceObject>(
    objectName: string,
    objects: T[],
    depth: number | undefined = undefined
  ): Promise<SalesforceResponse[]> {
    const params: Record<string, string | number> = {};
    if (depth !== undefined) {
      params.depth = depth;
    }
    const response = await super.post<SalesforceResponse[], T[]>({
      url: `${this.BASE_PATH}/${objectName}`,
      data: objects,
      config: { params },
    });
    return response?.data || [];
  }

  protected static async deleteById(
    objectName: string,
    ids: string[],
    depth: number | undefined = undefined
  ): Promise<SalesforceResponse[]> {
    const params: Record<string, string | number> = {};
    if (depth !== undefined) {
      params.depth = depth;
    }
    const response = await super.delete<SalesforceResponse[]>({
      url: `${this.BASE_PATH}/${objectName}/${ids.join(",")}`,
      config: { params },
    });
    return response?.data || [];
  }
}

export default FormSalesforceObjectService;
