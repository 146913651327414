import { FC, ReactElement } from "react";
import { Breadcrumb } from "react-bootstrap";
import AppPath from "../../../router/models/AppPath";

export type FormBreadcrumbProps = {
  paths: AppPath[];
};

const FormBreadcrumb: FC<FormBreadcrumbProps> = ({ paths }): ReactElement => {
  return (
    <Breadcrumb>
      <>
        {paths.map((path, index, { length }) =>
          path.toBreadcrumbItem(index === length - 1)
        )}
      </>
    </Breadcrumb>
  );
};

export default FormBreadcrumb;
