/* eslint-disable react/no-danger */
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DOMPurify from "dompurify";
import _ from "lodash";
import React, {
  createElement,
  FC,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { toast } from "react-toastify";
import useLoading from "../../../../hooks/useLoading";
import SalesforceManagedContent from "../../../../models/salesforce/ManagedContent";
import ManagedContentService from "../../../../services/forms/ManagedContentService";
import Modal from "../../../Modal";
import { EditableManagedContentProps } from "../../models/EditableManagedContentProps";
import { ManagedContentContext } from "../../models/ManagedContentContext";
import ManagedContentForm from "../ManagedContentForm";

export type ManagedContentFromSalesforceObjectProps = SalesforceManagedContent &
  EditableManagedContentProps & {
    as?: string;
    className?: string;
    editWrapperClassName?: string;
    render?: (content: string | undefined) => ReactNode;
    params?: Record<string, string | undefined>;
  };

const ManagedContentFromSalesforceObject: FC<
  ManagedContentFromSalesforceObjectProps
> = (props: ManagedContentFromSalesforceObjectProps): ReactElement => {
  const {
    id,
    content,
    onUpdate,
    editable: editableProp,
    as,
    className,
    editWrapperClassName,
    render,
    params,
  } = props;
  const { editable } = useContext(ManagedContentContext);
  const loading = useLoading();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [managedContent, setManagedContent] =
    useState<SalesforceManagedContent>({});

  useEffect(() => {
    setManagedContent((previousManagedContent) => ({
      ...previousManagedContent,
      ...props,
    }));
  }, [props]);

  const handleSubmit = () => {
    loading(async () => {
      const response = await ManagedContentService.saveManagedContent(
        managedContent
      );
      if (response?.success) {
        toast.success("Successfully saved managed content");
        setShowModal(false);
        if (onUpdate) {
          onUpdate({
            ...managedContent,
            id: response?.id,
          });
        }
      } else {
        [
          { message: "Failed to save managed content" },
          ...(response.errors || []),
        ].forEach(({ message }) => toast.error(message));
      }
    });
  };

  const element = useMemo(() => {
    if (render) {
      return render(content);
    }
    let newContent = content;
    if (params) {
      _.toPairs(params).forEach(([param, value]) => {
        if (value !== undefined) {
          newContent = newContent?.replaceAll(`{${param}}`, value);
        }
      });
    }
    return createElement(
      as || "span",
      { className },
      <span
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(newContent ?? ""),
        }}
      />
    );
  }, [as, className, content, params, render]);

  if (editableProp || (editable && editableProp !== false)) {
    return (
      <>
        <div
          id={id}
          className={`p-1${
            editWrapperClassName ? ` ${editWrapperClassName}` : ""
          }`}
          style={{
            position: "relative",
            border: "1px solid",
            borderRadius: "10px",
          }}
        >
          <>
            <FontAwesomeIcon
              icon={faPencilAlt}
              style={{
                position: "absolute",
                right: "-7px",
                top: "-7px",
                cursor: "pointer",
              }}
              onClick={() => setShowModal(true)}
            />
            {element}
          </>
        </div>
        <Modal
          id={`${id}-modal`}
          show={showModal}
          title="Edit Managed Content"
          body={
            <ManagedContentForm
              id={`${id}-modal-form`}
              managedContent={managedContent || {}}
              setManagedContent={setManagedContent}
            />
          }
          onCancel={() => setShowModal(false)}
          onSubmit={handleSubmit}
        />
      </>
    );
  }

  return <>{element}</>;
};

ManagedContentFromSalesforceObject.defaultProps = {
  as: "span",
  className: undefined,
  editWrapperClassName: undefined,
  render: undefined,
  params: undefined,
};

export default ManagedContentFromSalesforceObject;
