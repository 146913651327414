import AppPath from "../../../components/App/models/AppPath";

class RCRAPath extends AppPath {
  public static readonly RCRA = new AppPath({
    id: "rcra",
    label: "RCRA Student Form",
    path: "/rcra/:id?/:approvals?/:token?",
  });

  public static readonly RCRASuccess = new AppPath({
    id: "rcraSuccess",
    label: "Success Page",
    path: "/rcra-success",
  });
}
export default RCRAPath;
