import { FC, ReactElement, useCallback, useMemo, useState } from "react";
import {
  CellContext,
  ColumnFiltersState,
  PaginationState,
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import Case, { MOCStatus } from "../../../../models/salesforce/Case";
import Table from "../../../../../shared/src/modules/@tanslack/react-table/components/Table";
import Actions from "./components/Actions";
import useMOCRequestsByCurrentUser from "../../hooks/useMOCRequestsByCurrentUser";
import { SubmissionsTableContext } from "./models/SubmissionsTableContext";
import MOCPath from "../../../../models/MOCPath";
import FormControlFilter from "../../../../../shared/src/modules/@tanslack/react-table/filters/components/FormControlFilter";

export type SubmissionTableProps = {
  isApprover: boolean;
  statuses: MOCStatus[];
};
const SubmissionsTable: FC<SubmissionTableProps> = ({
  isApprover,
  statuses,
}): ReactElement => {
  const [data, setData] = useMOCRequestsByCurrentUser(isApprover, statuses);
  const history = useHistory();
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const isOnlyClosedOrRejectedStatuses = useMemo(() => {
    return statuses.every(
      (status) => status === MOCStatus.CLOSED || status === MOCStatus.REJECTED
    );
  }, [statuses]);

  const CheckCell = useCallback(
    ({
      row: {
        original: { fullyCertified },
      },
    }: CellContext<Case, unknown>) => {
      return (
        <FontAwesomeIcon
          icon={fullyCertified ? faCheckCircle : faTimesCircle}
          color={fullyCertified ? "green" : "red"}
          size="lg"
        />
      );
    },
    []
  );
  const columnHelper = createColumnHelper<Case>();
  const columns = useMemo(() => {
    return [
      columnHelper.accessor((row) => `PIM-${row.caseNumber}`, {
        header: "Case Number",
        enableSorting: true,
        meta: {
          filter: FormControlFilter,
        },
      }),
      columnHelper.accessor("programName", {
        header: "Program Name",
        enableSorting: true,
        meta: {
          filter: FormControlFilter,
        },
      }),
      columnHelper.accessor("programStartDate", {
        header: "Start Date",
        enableSorting: true,
        meta: {
          filter: FormControlFilter,
        },
      }),
      columnHelper.accessor("programEndDate", {
        header: "End Date",
        enableSorting: true,
        meta: {
          filter: FormControlFilter,
        },
      }),
      columnHelper.accessor("programDescription", {
        header: "Program Description",
        enableSorting: false,
        meta: {
          filter: FormControlFilter,
        },
      }),
      columnHelper.accessor("status", {
        header: "Status",
        enableSorting: false,
        meta: {
          filter: FormControlFilter,
        },
      }),
      columnHelper.display({
        id: "fullyCertified",
        header: "Fully Certified?",
        cell: CheckCell,
      }),
      columnHelper.display({
        id: "actions",
        header: "Actions",
        cell: Actions,
      }),
    ];
  }, [columnHelper, CheckCell]);
  const tableOptions = useMemo(
    () => ({
      columns,
      data,
      getCoreRowModel: getCoreRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getSortedRowModel: getSortedRowModel(),
      onPaginationChange: setPagination,
      onColumnFiltersChange: setColumnFilters,
      onSortingChange: setSorting,
      state: {
        pagination: {
          pageIndex,
          pageSize,
        },
        columnFilters,
        sorting,
      },
    }),
    [columns, data, pageIndex, pageSize, columnFilters, sorting]
  );

  const table = useReactTable<Case>(tableOptions);
  const tableHeaderProps = useMemo(() => ({ wrap: true }), []);
  const tablePagination = useMemo(
    () => ({
      totalSize: data?.length || 0,
      pageIndex,
      pageSize,
    }),
    [data, pageIndex, pageSize]
  );
  const tableContext = useMemo(() => {
    return { data, setData, isApprover, statuses };
  }, [data, setData, isApprover, statuses]);

  const handleClick = () => {
    history.push(MOCPath.MOC.getPath({}));
  };

  return (
    <SubmissionsTableContext.Provider value={tableContext}>
      {!isApprover && !isOnlyClosedOrRejectedStatuses && (
        <div className="mb-3">
          <Button variant="primary" onClick={handleClick}>
            Create New Submission
          </Button>
        </div>
      )}
      <div className="moc-submissions__submissions-table mb-5">
        <Table
          table={table}
          tableHeaderProps={tableHeaderProps}
          pagination={tablePagination}
        />
      </div>
    </SubmissionsTableContext.Provider>
  );
};

export default SubmissionsTable;
