/* eslint-disable react/jsx-props-no-spreading */
import _ from "lodash";
import React, { FC, ReactElement, useMemo } from "react";
import { Form } from "react-bootstrap";
import { renderContent } from "../../../Component/ComponentUtil";
import FormValidation from "../FormInput/components/FormValidation";
import FormInputProps from "../FormInput/models/FormInputProps";
import InfoIcon, { InfoIconProps } from "./components/InfoIcon";
import ComponentProps from "../../../../models/ComponentProps";

export type FormLabelProps = Pick<
  FormInputProps,
  "label" | "required" | "showRequired" | "requiredMessage"
> & {
  inline?: boolean;
  infoProps?: InfoIconProps | string;
};

const FormLabel: FC<FormLabelProps> = ({
  label,
  required,
  showRequired,
  inline,
  infoProps,
  requiredMessage,
}: FormLabelProps): ReactElement => {
  const infoIconProps: InfoIconProps = useMemo(() => {
    if (_.isString(infoProps)) {
      let parsedInfoProps;
      try {
        parsedInfoProps = JSON.parse(infoProps);
      } catch {
        parsedInfoProps = {};
      }
      return parsedInfoProps;
    }
    return infoProps;
  }, [infoProps]);

  if (_.isString(label) || React.isValidElement(label)) {
    return (
      <div className="form-label">
        <Form.Label className={inline ? "mb-0 me-3" : "mb-0"}>
          {required && (showRequired === undefined || showRequired) ? (
            <>
              {label}
              <span aria-labelledby="required-field" className="text-danger">
                *
              </span>
              <InfoIcon {...(infoIconProps || {})} />
              {requiredMessage && <FormValidation message={requiredMessage} />}
            </>
          ) : (
            <>
              {label}
              <InfoIcon {...(infoIconProps || {})} />
            </>
          )}
        </Form.Label>
      </div>
    );
  }

  if (required && (showRequired === undefined || showRequired)) {
    return (
      <div className="form-label">
        <>
          {renderContent(
            label as string | ComponentProps | ComponentProps[] | undefined
          )}
        </>
        <span aria-labelledby="required-field" className="text-danger">
          *
        </span>
        <InfoIcon {...(infoIconProps || {})} />
        {requiredMessage && <FormValidation message={requiredMessage} />}
      </div>
    );
  }
  return (
    <div className="form-label">
      <>
        {renderContent(
          label as string | ComponentProps | ComponentProps[] | undefined
        )}
      </>
      <InfoIcon {...(infoIconProps || {})} />
    </div>
  );
};

FormLabel.defaultProps = {
  inline: false,
  infoProps: undefined,
};

export default FormLabel;
