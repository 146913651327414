import {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button, Col, Container, Form, Navbar, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import FormDisplayer from "../../../shared/src/modules/form/components/FormDisplayer";
import AppPath from "../../../../components/App/models/AppPath";
import { RCRAFormRequest } from "../../models/RCRAFormRequest";
import RCRAPath from "../../models/RCRAPath";
import { RCRAFormContext } from "./models/RCRAFormContext";
import MyEmoryRCRASubmissionService, {
  RCRASubmissionSaveAction,
} from "../../services/MyEmoryRCRASubmissionService";
import useLoading from "../../../shared/src/hooks/useLoading";
import {
  mapRCRAFormRequestToRCRASubmission,
  RCRASubmission,
} from "../../models/salesforce/RCRASubmission";
import {
  getRequestByKey,
  setRequestByKey,
} from "../../../shared/src/components/Form";
import { ErrorResponse } from "../../../shared/src/models/ErrorResponse";

const RCRAStudentForm: FC = (): ReactElement => {
  const [initialRequest, setInitialRequest] = useState<RCRAFormRequest>({});
  const pathParams = useParams<{
    id: string;
    approvals: string;
    token: string;
  }>();
  const rcraRequestId = useMemo(() => pathParams?.id ?? "", [pathParams]);
  const approvalToken = useMemo(() => pathParams?.token ?? "", [pathParams]);
  const requestKey = useMemo(() => {
    return rcraRequestId !== "" ? `rcraMinors-${rcraRequestId}` : "rcraMinors";
  }, [rcraRequestId]);
  const action = useMemo(() => {
    return pathParams?.approvals as RCRASubmissionSaveAction;
  }, [pathParams]);
  const isApproval = useMemo(() => {
    return action === "School Approval";
  }, [action]);
  const loading = useLoading();
  const history = useHistory();

  const submittedDate = useMemo(() => {
    let date;
    switch (action) {
      case "Minor Approval":
        date = initialRequest?.minorCertificationDate;
        break;
      case "Parent Approval":
        date = initialRequest?.parentCertificationDate;
        break;
      case "School Approval":
        date = initialRequest?.schoolCertificationDate;
        break;
      default:
        break;
    }
    if (!date) {
      return new Date().toDateString();
    }
    return date;
  }, [action, initialRequest]);

  const refreshRequest = useCallback(async () => {
    return loading(
      MyEmoryRCRASubmissionService.getRCRASubmissionByIdTokenAndAction(
        rcraRequestId,
        approvalToken,
        action
      )
    ).then(async (response) => {
      const formRequest =
        await MyEmoryRCRASubmissionService.mapRCRASubmissionToRCRAFormRequest(
          response as RCRASubmission
        );
      setInitialRequest((previousInitialRequest) => {
        return {
          ...previousInitialRequest,
          ...formRequest,
          currentAction: action,
        };
      });
      setRequestByKey(requestKey, formRequest);
    });
  }, [action, approvalToken, loading, rcraRequestId, requestKey]);

  useEffect(() => {
    if (rcraRequestId && approvalToken && action) {
      refreshRequest();
    } else {
      toast.error(
        "Invalid request. Please use pre-generated links to access this page."
      );
    }
  }, [action, approvalToken, rcraRequestId, refreshRequest]);

  const handleAxiosError = useCallback((error: AxiosError) => {
    const errorResponse = error.response?.data as ErrorResponse;
    const errors = errorResponse?.errors;
    if (errors && Array.isArray(errors)) {
      errors.forEach((err) => {
        toast.error(
          `Failed to save form: ${err.field ? `${err.field} - ` : ""}${
            err.defaultMessage
          }`
        );
      });
    }
  }, []);

  const handleSave = useCallback(
    async (saveAction: RCRASubmissionSaveAction) => {
      return loading(async () => {
        if (!approvalToken || !rcraRequestId || !saveAction) {
          toast.error(
            "Invalid request. Please use pre-generated links to access this page."
          );
        } else {
          try {
            const formRequest = getRequestByKey(requestKey);
            setInitialRequest(formRequest);
            const request = mapRCRAFormRequestToRCRASubmission(formRequest);
            const response = await MyEmoryRCRASubmissionService.validateAndSave(
              request,
              saveAction
            );
            if (response.every((res) => res.success)) {
              toast.success("Form saved successfully");
              history.push(RCRAPath.RCRASuccess.getPath());
            }
            response.forEach((res) => {
              res.errors?.forEach((error) => {
                toast.error(`Failed to save form: ${error.message}`);
              });
            });
          } catch (error) {
            handleAxiosError(error as AxiosError);
          }
        }
      });
    },
    [
      approvalToken,
      rcraRequestId,
      loading,
      requestKey,
      history,
      handleAxiosError,
    ]
  );

  useEffect(() => {
    loading(async () => {
      localStorage.removeItem(requestKey);
    }).catch(() =>
      toast.error(`An error occurred while clearing cached ${requestKey}"`)
    );
  }, [loading, requestKey]);

  const rcraFormContext = useMemo(() => {
    return { action };
  }, [action]);
  return (
    <div>
      <RCRAFormContext.Provider value={rcraFormContext}>
        <Container>
          <FormDisplayer
            formFeatureName="RCRA Minors - Minor Form"
            initialRequest={initialRequest}
            requestKey={requestKey}
            formPagePaths={[AppPath.Home, RCRAPath.RCRA]}
          />
        </Container>
      </RCRAFormContext.Provider>
      <Navbar
        fixed="bottom"
        className="d-flex justify-content-center border-top bg-white"
      >
        {!isApproval ? (
          <div>
            <Button variant="primary" onClick={() => handleSave(action)}>
              Submit
            </Button>
          </div>
        ) : (
          <Container>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Submitted Date</Form.Label>
                  <Form.Control type="text" disabled value={submittedDate} />
                </Form.Group>
              </Col>
              <Col>
                <Button
                  className="mt-3"
                  variant="success"
                  onClick={() => handleSave(action)}
                >
                  Approve
                </Button>
              </Col>
            </Row>
          </Container>
        )}
      </Navbar>
    </div>
  );
};

export default RCRAStudentForm;
