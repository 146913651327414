import React, { FC, ReactElement } from "react";
import {
  Row as BootstrapRow,
  RowProps as BootstrapRowProps,
} from "react-bootstrap";
import { ComponentProps } from "../../models";
import { renderContent } from "../Component/ComponentUtil";

export interface RowProps extends BootstrapRowProps {
  content?: ComponentProps | ComponentProps[];
}

const Row: FC<RowProps> = ({ content, ...props }: RowProps): ReactElement => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <BootstrapRow {...props}>
      <>{renderContent(content)}</>
    </BootstrapRow>
  );
};

Row.defaultProps = {
  content: undefined,
};

export default Row;
