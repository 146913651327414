import _ from "lodash";
import { toast } from "react-toastify";
import { setPropByIds } from "../components/Component/ComponentUtil";
import { DEFAULT_OTHER_LABEL } from "../components/Form/components/FormInput/models/FormOtherProps";
import { ComponentProps } from "../models";
import { ValidationError } from "../models/ErrorResponse";
import SectionColumn from "../models/salesforce/SectionColumn";

const getSectionColumns = (
  form: ComponentProps | undefined
): ComponentProps[] => {
  const formSections = (form?.content as ComponentProps[]) || [];
  const sectionRows = formSections.flatMap(
    ({ content }) => (content as ComponentProps[]) || []
  );
  return sectionRows.flatMap(
    ({ content }) => (content as ComponentProps[]) || []
  );
};

const getValidationTargetByName = (
  sectionColumns: ComponentProps[]
): _.Dictionary<ComponentProps> => {
  const formCustomComponents = sectionColumns
    .filter((sectionColumnComponentProps) => {
      const sectionColumn =
        sectionColumnComponentProps?.salesforceObject as SectionColumn;
      return (
        sectionColumn.type === "CustomComponent" &&
        sectionColumn.formCustomComponent
      );
    })
    .map(({ content }) => content as ComponentProps);
  const formInputs = sectionColumns
    .filter((sectionColumnComponentProps) => {
      const sectionColumn =
        sectionColumnComponentProps?.salesforceObject as SectionColumn;
      return sectionColumn.type === "FormInput" && sectionColumn.formInput;
    })
    .map(({ content }) => content as ComponentProps);
  const nameToFormCustomComponentsPairs = formCustomComponents.map(
    (formCustomComponent) => {
      return [
        (formCustomComponent.content as Record<string, string>).tag,
        formCustomComponent,
      ];
    }
  );
  const nameToFormInputPairs = formInputs
    .map((formInput) => {
      const formInputProps = (formInput?.props as Record<string, string>) || {};
      const currentFormInputByName = [[formInputProps.name, formInput]];
      if (formInputProps?.otherName) {
        currentFormInputByName.push([formInputProps.otherName, formInput]);
      }
      if (formInputProps?.otherProps) {
        (
          JSON.parse(formInputProps?.otherProps) as Record<string, string>[]
        ).forEach((currentOtherProps) => {
          currentFormInputByName.push([
            currentOtherProps.name,
            currentOtherProps.id
              ? {
                  tag: "IGNORED",
                  props: {
                    ...currentOtherProps,
                  },
                }
              : formInput,
          ]);
        });
      }
      return currentFormInputByName;
    })
    .flat(1);
  return _.fromPairs([
    ...nameToFormInputPairs,
    ...nameToFormCustomComponentsPairs,
  ]);
};

const getValidationTargetOrderById = (
  validationTargetByName: _.Dictionary<ComponentProps>,
  form: ComponentProps | undefined
): _.Dictionary<number> => {
  const formAsString = JSON.stringify(form);
  return _.fromPairs(
    _.keys(validationTargetByName).map((fieldName) => {
      const fieldId = (
        validationTargetByName[fieldName]?.props as Record<string, string>
      )?.id;
      const index = formAsString.indexOf(fieldId);
      return [fieldId, index === -1 ? formAsString.length : index];
    })
  );
};

const clearValidationMessagesByFieldNames = (
  previousFormContent: ComponentProps | undefined,
  validationTargetByName: _.Dictionary<ComponentProps>,
  ...fieldNames: string[]
): ComponentProps | undefined => {
  let newFormContent = previousFormContent;
  fieldNames.forEach((fieldName) => {
    const fieldId = (
      validationTargetByName[fieldName]?.props as Record<string, string>
    )?.id;
    newFormContent = setPropByIds(
      [fieldId],
      "validationMessage",
      "",
      newFormContent
    );
    newFormContent = setPropByIds([fieldId], "invalid", false, newFormContent);
  });
  return newFormContent;
};

const getFieldLabel = (
  validationTargetByName: _.Dictionary<ComponentProps>,
  fieldName: string
) => {
  const validationTargetProps =
    (validationTargetByName[fieldName]?.props as Record<string, unknown>) || {};
  if (validationTargetProps?.otherName === fieldName) {
    return validationTargetProps?.otherLabel || DEFAULT_OTHER_LABEL;
  }
  if (validationTargetProps?.otherProps) {
    let otherPropsLabel = DEFAULT_OTHER_LABEL;
    (
      JSON.parse(validationTargetProps?.otherProps as string) as Record<
        string,
        string
      >[]
    ).some(({ name, label }) => {
      if (name === fieldName) {
        otherPropsLabel = label;
        return true;
      }
      return false;
    });
    return otherPropsLabel;
  }
  if (_.isObjectLike(validationTargetProps?.label)) {
    return (validationTargetProps?.label as Record<string, string>).content;
  }
  return validationTargetProps?.label;
};

const replaceAllFieldNamesWithFieldLabels = (
  message: string,
  validationTargetByName: _.Dictionary<ComponentProps>
): {
  fieldNames: (string | undefined)[];
  formattedMessage: string;
} => {
  const targetFieldNames = [];
  let formattedMessage = message;
  let fieldMatch = formattedMessage?.match(/\[[\w.]*\]/);
  while (fieldMatch && fieldMatch[0] && !_.isUndefined(fieldMatch.index)) {
    const fieldName = fieldMatch[0].substring(1, fieldMatch[0].length - 1);
    const fieldLabel = getFieldLabel(validationTargetByName, fieldName);
    const formattedFieldLabel = fieldLabel ? `"${fieldLabel}"` : "";
    targetFieldNames.push(fieldName);
    formattedMessage = `${formattedMessage.substring(
      0,
      fieldMatch.index
    )}${formattedFieldLabel}${formattedMessage.substring(
      fieldMatch.index + fieldMatch[0].length
    )}`;
    fieldMatch = formattedMessage.match(/\[\w*\]/);
  }
  return {
    fieldNames: targetFieldNames,
    formattedMessage,
  };
};

const DEFAULT_ERROR_CONDITION_DELIMITER = " is required if ";

const formatErrorMessage = (
  field: string | undefined,
  message: string,
  validationTargetByName: _.Dictionary<ComponentProps>,
  errorConditionDelimiters: string[] = [DEFAULT_ERROR_CONDITION_DELIMITER],
  excludeFieldLabel = false
): {
  fieldNames: (string | undefined)[];
  formattedMessage: string;
} => {
  if (field) {
    const fieldLabel = getFieldLabel(validationTargetByName, field);
    let formattedMessage = message;
    if (!excludeFieldLabel || !fieldLabel) {
      formattedMessage = `"${fieldLabel}" ${message}`;
    }
    return {
      fieldNames: [field],
      formattedMessage,
    };
  }
  const errorConditionDelimiter =
    errorConditionDelimiters.find((x) => message.includes(x)) ??
    DEFAULT_ERROR_CONDITION_DELIMITER;
  const [targetFieldNamesPart, errorConditionPart] = message.split(
    errorConditionDelimiter,
    2
  );
  const {
    fieldNames: targetFieldNames,
    formattedMessage: formattedTargetFieldNamesPart,
  } = replaceAllFieldNamesWithFieldLabels(
    targetFieldNamesPart,
    validationTargetByName
  );
  const { formattedMessage: formattedErrorConditionPart } =
    replaceAllFieldNamesWithFieldLabels(
      errorConditionPart,
      validationTargetByName
    );
  return {
    fieldNames: targetFieldNames,
    formattedMessage: message
      .replace(targetFieldNamesPart, formattedTargetFieldNamesPart)
      .replace(errorConditionPart, formattedErrorConditionPart),
  };
};

const setValidationMessagesForErrors = (
  previousFormContent: ComponentProps | undefined,
  validationTargetByName: _.Dictionary<ComponentProps>,
  errors: ValidationError[],
  errorConditionDelimiters: string[] = [DEFAULT_ERROR_CONDITION_DELIMITER]
): [ComponentProps | undefined, string[]] => {
  const errorFieldIds: string[] = [];
  let newFormContent = previousFormContent;
  errors.forEach(({ field, defaultMessage, excludeFieldLabel }) => {
    const { fieldNames, formattedMessage } = formatErrorMessage(
      field,
      defaultMessage,
      validationTargetByName,
      errorConditionDelimiters,
      excludeFieldLabel
    );
    if (fieldNames.length > 0) {
      fieldNames.forEach((fieldName) => {
        const fieldId = fieldName
          ? (validationTargetByName[fieldName]?.props as Record<string, string>)
              ?.id
          : undefined;
        if (fieldId) {
          errorFieldIds.push(fieldId);
          newFormContent = setPropByIds(
            [fieldId],
            "validationMessage",
            formattedMessage,
            newFormContent
          );
          newFormContent = setPropByIds(
            [fieldId],
            "invalid",
            true,
            newFormContent
          );
        } else {
          // eslint-disable-next-line no-console
          console.error(`Failed to find fieldId for fieldName: ${fieldName}`);
        }
      });
    } else {
      toast.error(formattedMessage, {
        autoClose: false,
      });
    }
  });
  return [newFormContent, errorFieldIds];
};

export {
  clearValidationMessagesByFieldNames,
  formatErrorMessage,
  getSectionColumns,
  getValidationTargetByName,
  getValidationTargetOrderById,
  setValidationMessagesForErrors,
};

export type SidebarNavLink = {
  level: number;
  id: string;
  text: string;
  sectionColumn: SectionColumn;
};

export const getSidebarNavLinks = (
  sectionColumns: ComponentProps[]
): SidebarNavLink[] => {
  return sectionColumns
    .filter((sectionColumnComponentProps) => {
      const sectionColumn =
        sectionColumnComponentProps?.salesforceObject as SectionColumn;
      return sectionColumn.type === "ManagedContent" && sectionColumn.content;
    })
    .map(({ content, salesforceObject }) => {
      const sectionColumn = salesforceObject as SectionColumn;
      return [(content as { content: string }).content, sectionColumn] as [
        string,
        SectionColumn
      ];
    })
    .filter(([content]) => /<h\d id="/.test(content))
    .map(([content, sectionColumn]) => {
      const [headerLevel, ...remainingContent1] = content.split(' id="');
      const [id, ...remainingPart2] = remainingContent1
        .join(' id="')
        .split('"');
      const [, ...remainingPart3] = remainingPart2.join('"').split(">");
      const text = remainingPart3.join(">").split("<", 1)[0].trim();
      return {
        level: parseInt(headerLevel.charAt(2), 10),
        id,
        text,
        sectionColumn,
      };
    });
};

export const hasSidebarNavLinksChanged = (
  previousSidebarNavLinks: SidebarNavLink[],
  updatedForm: ComponentProps | undefined
): boolean => {
  const newSidebarNavLinks = getSidebarNavLinks(getSectionColumns(updatedForm));
  const previousJson = JSON.stringify(previousSidebarNavLinks).replace(
    /\s+/g,
    ""
  );
  const newJson = JSON.stringify(newSidebarNavLinks).replace(/\s+/g, "");
  if (newJson === "[]") {
    return false;
  }
  return previousJson !== newJson;
};
