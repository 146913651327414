import { ReactElement, ReactNode } from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import _ from "lodash";
import AppHeaderLink, {
  AppHeaderLinkType,
} from "../../../components/AppHeader/AppHeaderLink";
import AppRouteProps from "../../../models/AppRouteProps";
import Feature from "../../../models/salesforce/Feature";

export type AppPathType = Omit<AppHeaderLink, "type"> &
  Omit<AppRouteProps, "path"> & {
    path: string;
    type?: AppHeaderLinkType;
    showOnMenu?: boolean;
    featureName?: string;
  };

class AppPath {
  private static ALL_VALUES: AppPath[] = [];

  public readonly id: string;

  public readonly type?: AppHeaderLinkType;

  public readonly label: ReactNode | undefined;

  public readonly path: string;

  public readonly exact: boolean | undefined;

  public readonly showOnMenu: boolean;

  public readonly featureName: string | undefined;

  constructor({
    id,
    type,
    label,
    path,
    exact,
    showOnMenu,
    featureName,
  }: AppPathType) {
    this.id = id;
    this.type = type;
    this.label = label;
    this.path = path;
    this.exact = exact;
    this.showOnMenu = !!showOnMenu;
    this.featureName = featureName;

    AppPath.ALL_VALUES.push(this);
  }

  public toBreadcrumbItem(active = false): ReactElement {
    if (active) {
      return (
        <Breadcrumb.Item active key={this.id}>
          <>{this.label}</>
        </Breadcrumb.Item>
      );
    }
    return (
      <Breadcrumb.Item
        key={this.id}
        linkAs={Link}
        linkProps={{ to: this.path }}
      >
        <>{this.label}</>
      </Breadcrumb.Item>
    );
  }

  public getPath(pathParams?: Record<string, string | undefined>): string {
    if (_.isUndefined(pathParams)) {
      if (this.path.includes(":")) {
        return this.path.replace(/\/:.*\??/, "");
      }
      return this.path;
    }
    let { path } = this;
    _.forOwn(pathParams, (paramValue, paramName) => {
      const regex = new RegExp(`:${paramName}\\??`);
      const match = path?.match(regex);
      if (match?.[0] && match.index) {
        path = `${path.substring(0, match.index)}${paramValue}${path.substring(
          match.index + match[0].length
        )}`;
      }
    });
    return path;
  }

  public static getAppPaths(appPathFeatures?: Feature[]): AppPath[] {
    return [...AppPath.ALL_VALUES].filter(({ featureName }) => {
      return (
        appPathFeatures === undefined ||
        featureName === undefined ||
        appPathFeatures.find(({ name }) => name === featureName)?.enabled
      );
    });
  }

  public static getAppHeaderLinks(
    appPathFeatures: Feature[] = []
  ): AppHeaderLink[] {
    return this.getAppPaths(appPathFeatures).filter(
      ({ showOnMenu, type }) => showOnMenu && !_.isUndefined(type)
    ) as AppHeaderLink[];
  }

  public static getAppRoutes(
    additionalAppRoutes: AppRouteProps[] = [],
    appPathFeatures: Feature[] = []
  ): AppRouteProps[] {
    const appRoutes: AppRouteProps[] = this.getAppPaths(appPathFeatures);
    appRoutes.splice(appRoutes.length - 1, 0, ...additionalAppRoutes);
    return appRoutes;
  }
}

export default AppPath;
