import { IconDefinition, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, ReactElement, useEffect, useState } from "react";
import "./styles.scss";

export type LoadingProps = {
  size?: number;
  icon?: IconDefinition;
};

const DEFAULT_SIZE = 10;

const Loading: FC<LoadingProps> = ({
  size: sizeProp,
  icon = faSpinner,
}: LoadingProps): ReactElement => {
  const [size, setSize] = useState<number>(DEFAULT_SIZE);

  useEffect(() => {
    if (sizeProp) {
      setSize(sizeProp);
    }
  }, [sizeProp]);

  return (
    <div className="loading">
      <div className="loading-overlay">
        <div
          className="loading-spinner"
          style={{
            marginTop: (-16 * size) / 2,
            marginLeft: (-16 * size) / 2,
          }}
        >
          <FontAwesomeIcon icon={icon} spin size={`${size}x` as SizeProp} />
        </div>
      </div>
    </div>
  );
};

Loading.defaultProps = {
  size: DEFAULT_SIZE,
  icon: faSpinner,
};

export default Loading;
