import _ from "lodash";
import { Dispatch, SetStateAction } from "react";
import {
  addClassNames,
  removeClassNames,
  setPropByIds,
} from "../../components/Component/ComponentUtil";
import {
  FormRequest,
  parseValue,
} from "../../components/Form/models/FormRequest";
import { ComponentProps } from "../../models";
import FormRule from "../../models/salesforce/FormRule";
import FormRuleAction from "./FormRuleAction";
import {
  evaluateRule,
  parseDependentFieldNamesFromRule,
} from "./FormRuleParser";
import { FormContextProps } from "../../components/Form/models/FormContext";

export const getTargetId = ({
  type,
  formSectionId,
  sectionRowId,
  sectionColumnId,
  formInputId,
}: FormRule): string | undefined => {
  let targetId: string | undefined;
  if (type === "Section") {
    targetId = formSectionId;
  } else if (type === "Row") {
    targetId = sectionRowId;
  } else if (type === "Column") {
    targetId = sectionColumnId;
  } else if (type === "Form Input") {
    targetId = formInputId;
  } else {
    throw new Error(`Invalid form rule type: ${type}`);
  }
  return targetId;
};

export const parseDependentFieldNamesFromFormRule = (
  formRule: FormRule
): string[] => {
  const targetId = getTargetId(formRule);
  const { rule } = formRule;
  if (targetId && rule) {
    const [, ruleExpression] = rule.split(" | ", 2);
    return parseDependentFieldNamesFromRule(ruleExpression);
  }
  return [];
};

export const parseDependentFieldNamesFromAllFormRules = (
  formRules: FormRule[]
): string[] => {
  return formRules.flatMap(parseDependentFieldNamesFromFormRule);
};

export const processFormRules = (
  formContent: ComponentProps | undefined,
  formRules: FormRule[] | undefined,
  request: FormRequest,
  setRequest: Dispatch<SetStateAction<FormRequest>>,
  modifiedTargetIds: string[],
  validateFields: FormContextProps["validateFields"] = undefined
): ComponentProps | undefined => {
  if (!formContent || !formRules) {
    return formContent;
  }
  let newFormContent = formContent;
  let newRequest = request;
  const ruleEvaluationCache = new Map<string, boolean>();
  const modifiedRequestFieldNames: string[] = [];
  formRules.forEach((formRule) => {
    const targetId = getTargetId(formRule);
    const { rule } = formRule;
    if (targetId && rule) {
      const [action, ruleExpression] = rule.split(" | ", 2);
      const isMatch = evaluateRule(
        ruleExpression,
        request,
        ruleEvaluationCache
      );
      if (FormRuleAction.SHOW.isMatch(action)) {
        newFormContent = setPropByIds(
          [targetId],
          "className",
          undefined,
          newFormContent,
          (currentValue) => {
            const currentClassNames = (currentValue as string) ?? "";
            if (isMatch) {
              return removeClassNames(currentClassNames, ["d-none"]);
            }
            return addClassNames(currentClassNames, ["d-none"]);
          }
        );
      } else if (FormRuleAction.HIDE.isMatch(action)) {
        newFormContent = setPropByIds(
          [targetId],
          "className",
          undefined,
          newFormContent,
          (currentValue) => {
            const currentClassNames = (currentValue as string) ?? "";
            if (isMatch) {
              return addClassNames(currentClassNames, ["d-none"]);
            }
            return removeClassNames(currentClassNames, ["d-none"]);
          }
        );
      } else if (FormRuleAction.SET_PROP.isMatch(action)) {
        const [propName, matchValue, nonmatchValue] = action
          .substring("SET_PROP(".length, action.length - ")".length)
          .split(",", 3)
          .map((x) => x.trim());
        const parsedMatchValue = parseValue(matchValue, request);
        const parsedNonmatchValue = parseValue(nonmatchValue, request);
        newFormContent = setPropByIds(
          [targetId],
          propName,
          undefined,
          newFormContent,
          () => (isMatch ? parsedMatchValue : parsedNonmatchValue)
        );
      } else if (FormRuleAction.SET_VALUE.isMatch(action)) {
        if (modifiedTargetIds.includes(targetId)) {
          const actionContent = action.substring(
            "SET_VALUE(".length,
            action.length - ")".length
          );
          const delimiterIndex = actionContent.indexOf(",");
          const matchValue = actionContent.slice(delimiterIndex + 1).trim();
          const fieldName = actionContent.slice(0, delimiterIndex).trim();
          const parsedMatchValue = parseValue(matchValue, request);
          if (isMatch && _.get(request, fieldName) !== parsedMatchValue) {
            newRequest = _.setWith(
              _.clone(newRequest),
              fieldName,
              parsedMatchValue,
              _.clone
            );
            modifiedRequestFieldNames.push(fieldName);
          }
        }
      } else {
        throw new Error(`Invalid rule action in rule: ${rule}`);
      }
    }
  });
  if (!_.isEqual(newRequest, request)) {
    setRequest(newRequest);
    if (validateFields) {
      validateFields(newRequest, ...modifiedRequestFieldNames);
    }
  }
  return newFormContent;
};

export default { processFormRules };
