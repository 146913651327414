import { FC, ReactElement, useContext } from "react";
import { RCRAFormContext } from "../../models/RCRAFormContext";
import ManagedContent from "../../../../../shared/src/components/ManagedContent";
import ManagedContentName from "../../../../models/ManagedContentName";
import FeatureName from "../../../../../../components/App/models/FeatureName";
import { RCRASubmissionSaveAction } from "../../../../services/MyEmoryRCRASubmissionService";

const RCRAAgreements: FC = (): ReactElement => {
  const { action } = useContext(RCRAFormContext);
  const agreementsMap: Map<RCRASubmissionSaveAction, ManagedContentName> =
    new Map([
      ["Minor Approval", ManagedContentName.RCRAMinorsStudentAgreement],
      ["Parent Approval", ManagedContentName.RCRAMinorsParentAgreement],
      ["School Approval", ManagedContentName.RCRAMinorsSchoolAgreement],
    ]);

  return (
    <div className="mb-5">
      <ManagedContent
        name={
          agreementsMap.get(action) ??
          ManagedContentName.RCRAMinorsStudentAgreement
        }
        featureName={FeatureName.RCRAMinors}
      />
    </div>
  );
};

export default RCRAAgreements;
