import { CacheAxiosResponse } from "axios-cache-interceptor";
import _ from "lodash";
import {
  ChangeEvent,
  ChangeEventHandler,
  FC,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Form } from "react-bootstrap";
import { FormCheckType } from "react-bootstrap/esm/FormCheck";
import { v4 as uuid } from "uuid";
import { useSafeAsync } from "../../../../../../hooks";
import { BaseService } from "../../../../../../services";
import { FormContext } from "../../../../models/FormContext";
import { isFormOption } from "../../../../models/FormRequest";
import FormLabel from "../../../FormLabel";
import FormInputProps from "../../models/FormInputProps";
import FormOption from "../../models/FormOption";
import { formatOptionsUrl } from "../../utils/FormInputUtil";
import FormValidation from "../FormValidation";
import FormCheck, { FormCheckProps } from "./FormCheck";

export interface FormCheckListProps extends FormInputProps {
  options: FormCheckProps[];
  onChange?: ChangeEventHandler<HTMLInputElement>;
  inline?: boolean;
  inlineLabel?: boolean;
  disabled?: boolean;
  formCheckProps?: string;
}

type Value = string | string[] | FormOption | undefined;

const isChecked = (
  type: FormCheckType,
  value: Value,
  optionProps: FormCheckProps
): boolean => {
  if (type === "checkbox") {
    return ((value ?? []) as string[]).includes(optionProps.value);
  }
  if (isFormOption(value)) {
    return (value as FormOption).value === optionProps.value;
  }
  return value === optionProps.value;
};

const FormCheckList: FC<FormCheckListProps> = ({
  id,
  name,
  label,
  type,
  required,
  showRequired,
  onChange,
  options,
  optionsEndpoint,
  inline,
  inlineLabel,
  className,
  disabled,
  invalid,
  validationMessage,
  infoProps,
  requiredMessage,
  onAfterChange,
  formCheckProps: formCheckPropsString,
}: FormCheckListProps): ReactElement => {
  const safeAsync = useSafeAsync();
  const [value, setValue] = useState<Value>();
  const { initialRequest, setRequest, optionsByFieldNameMap } =
    useContext(FormContext);
  const [stateOptions, setStateOptions] = useState<FormCheckProps[]>(
    options ?? []
  );

  // TODO: update usage from initialRequest to actual request
  const formattedOptionsEndpoint = useMemo(() => {
    if (optionsEndpoint) {
      return formatOptionsUrl(optionsEndpoint, initialRequest);
    }
    return optionsEndpoint;
  }, [optionsEndpoint, initialRequest]);

  useEffect(() => {
    if ((optionsByFieldNameMap?.[name] ?? []).length > 0) {
      setStateOptions(
        (optionsByFieldNameMap?.[name] ?? []) as FormCheckProps[]
      );
    } else if ((_.get(optionsByFieldNameMap, name) ?? []).length > 0) {
      setStateOptions(
        (_.get(optionsByFieldNameMap, name) ?? []) as FormCheckProps[]
      );
    } else if (formattedOptionsEndpoint) {
      safeAsync(
        BaseService.get<FormCheckProps[]>({ url: formattedOptionsEndpoint })
      )
        .then((response) => response as CacheAxiosResponse<FormCheckProps[]>)
        .then((response) => response.data || [])
        .then(setStateOptions);
    }
  }, [formattedOptionsEndpoint, name, optionsByFieldNameMap, safeAsync]);

  const isInline = useCallback(() => {
    return inline ?? stateOptions.every((x) => x?.inline);
  }, [inline, stateOptions]);

  const onChangeHandler = useMemo(() => {
    return (event: ChangeEvent<HTMLInputElement>, newValue?: Value) => {
      event.preventDefault();
      event.stopPropagation();
      if (onChange !== undefined) {
        onChange(event);
      } else if (setRequest !== undefined) {
        setRequest((request) => {
          return _.setWith(_.clone(request), name, newValue, _.clone);
        });
      }
      if (onAfterChange) {
        onAfterChange(event);
      }
    };
  }, [name, onAfterChange, onChange, setRequest]);

  const handleOnChange = useMemo(() => {
    return (event: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value: optionValue, checked },
      } = event;
      setValue((previousValue) => {
        let newValue: Value;
        if (type === "checkbox") {
          if (checked && !_.isArray(previousValue)) {
            newValue = [optionValue];
          } else if (_.isArray(previousValue)) {
            if (checked) {
              newValue = _.uniq([...previousValue, optionValue]);
            } else {
              newValue = previousValue.filter((x) => x !== optionValue);
            }
          } else {
            newValue = previousValue;
          }
        } else if (isFormOption(previousValue)) {
          newValue = stateOptions.find(
            ({ value: stateOptionValue }) => optionValue === stateOptionValue
          ) as Value;
        } else {
          newValue = optionValue;
        }
        onChangeHandler(event, newValue);
        return newValue;
      });
    };
  }, [onChangeHandler, stateOptions, type]);

  useEffect(() => {
    setValue(_.get(initialRequest, name) as Value);
  }, [initialRequest, name]);

  const formCheckProps = useMemo(() => {
    if (formCheckPropsString) {
      return JSON.parse(formCheckPropsString) as Record<string, unknown>;
    }
    return {};
  }, [formCheckPropsString]);

  const formChecks = (
    <>
      {(Array.isArray(stateOptions) ? stateOptions : []).map(
        (optionProps, index) => {
          const uniqueId = `${id}-${uuid()}`;
          return (
            <FormCheck
              {...optionProps}
              {...formCheckProps}
              inline={isInline()}
              key={uniqueId}
              id={uniqueId}
              type={type as FormCheckType}
              name={name}
              required={index === 0 ? required : false}
              checked={isChecked(type as FormCheckType, value, optionProps)}
              onChange={handleOnChange}
            />
          );
        }
      )}
    </>
  );

  return (
    <fieldset disabled={disabled}>
      <Form.Group controlId={id} className={className}>
        {label && (
          <FormLabel
            label={label}
            required={required}
            showRequired={showRequired}
            inline={inlineLabel && isInline()}
            infoProps={infoProps}
            requiredMessage={requiredMessage}
          />
        )}
        <div className={invalid ? "form-invalid-input" : ""}>
          {inlineLabel ? formChecks : <div>{formChecks}</div>}
        </div>
        {validationMessage && <FormValidation message={validationMessage} />}
      </Form.Group>
    </fieldset>
  );
};

FormCheckList.defaultProps = {
  onChange: undefined,
  inline: false,
  inlineLabel: false,
  disabled: false,
  formCheckProps: undefined,
};

export default FormCheckList;
