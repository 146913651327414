import _ from "lodash";
import { Dispatch, SetStateAction } from "react";
import FormOption, {
  formOptionsToSalesforcePicklistString,
} from "../components/FormInput/models/FormOption";

export interface FormRequest {
  [index: string]: unknown;
}

export interface RequestStateProps<T = FormRequest> {
  initialRequest: T;
  getRequest?: () => T;
  setRequest: Dispatch<SetStateAction<T>>;
}

export const isFormOption = (value: unknown): boolean => {
  return (
    _.isObjectLike(value) && _.has(value, "label") && _.has(value, "value")
  );
};

const isFormOptionArray = (value: unknown): boolean => {
  return (
    _.isArray(value) &&
    (value.length === 0 ||
      (_.has(value[0], "label") && _.has(value[0], "value")))
  );
};

const isStringArray = (value: unknown): boolean => {
  return _.isArray(value) && (value.length === 0 || _.isString(value[0]));
};

export type RequestValueComparisonFunction = (
  requestValue: unknown,
  targetValue: unknown[]
) => boolean;

export const evaluateEquals: RequestValueComparisonFunction = (
  requestValue,
  targetValue
) => {
  return (
    (_.isString(requestValue) && requestValue === targetValue[0]) ||
    (isFormOption(requestValue) &&
      (requestValue as FormOption).value === targetValue[0])
  );
};

export const evaluateNotEquals: RequestValueComparisonFunction = (
  requestValue,
  targetValue
) => {
  return (
    (_.isString(requestValue) && requestValue !== targetValue[0]) ||
    (isFormOption(requestValue) &&
      (requestValue as FormOption).value !== targetValue[0])
  );
};

export const evaluateMultiselectContains: RequestValueComparisonFunction = (
  requestValue,
  targetValue
) => {
  return (
    isFormOptionArray(requestValue) &&
    (requestValue as FormOption[]).some(({ value }) => value === targetValue[0])
  );
};

export const evaluateMultiselectNotContains: RequestValueComparisonFunction = (
  requestValue,
  targetValue
) => {
  return (
    isFormOptionArray(requestValue) &&
    (requestValue as FormOption[]).every(
      ({ value }) => value !== targetValue[0]
    )
  );
};

export const evaluateIsTruthy: RequestValueComparisonFunction = (
  requestValue
) => {
  return !!requestValue;
};

export const evaluateIsFalsy: RequestValueComparisonFunction = (
  requestValue
) => {
  return !requestValue;
};

export const evaluateChecklistContains: RequestValueComparisonFunction = (
  requestValue,
  targetValue
) => {
  return (
    isStringArray(requestValue) &&
    (requestValue as string[]).some((value) => value === targetValue[0])
  );
};

export const evaluateChecklistNotContains: RequestValueComparisonFunction = (
  requestValue,
  targetValue
) => {
  return (
    isStringArray(requestValue) &&
    (requestValue as string[]).every((value) => value !== targetValue[0])
  );
};

export const evaluateIsEmpty: RequestValueComparisonFunction = (
  requestValue
) => {
  return _.isEmpty(requestValue);
};

export const getValueForRequestPath = (
  requestPath: string,
  request: FormRequest
): unknown => {
  const path = requestPath.substring("[request].".length);
  return _.get(request, path);
};

export const formatRequestValueAsString = (requestValue: unknown): string => {
  if (isFormOption(requestValue)) {
    return (requestValue as FormOption).value;
  }
  if (isFormOptionArray(requestValue)) {
    return (
      formOptionsToSalesforcePicklistString(requestValue as FormOption[]) ?? ""
    );
  }
  if (requestValue) {
    return `${requestValue}`;
  }
  return "";
};

const REQUEST_PATH_REGEX = /{\[request\][\w.]*}/;

export const replaceRequestPath = (
  value: string,
  request: FormRequest
): string => {
  const match = value.match(REQUEST_PATH_REGEX);
  if (match && match[0] && match.index) {
    const requestPath = match[0].substring(1, match[0].length - 1);
    const matchValue = formatRequestValueAsString(
      getValueForRequestPath(requestPath, request)
    );
    return `${value.substring(0, match.index)}${matchValue}${value.substring(
      match.index + match[0].length
    )}`;
  }
  return value;
};

export const parseValue = (value: string, request: FormRequest): unknown => {
  if (value.startsWith("[request].")) {
    return getValueForRequestPath(value, request);
  }
  if (REQUEST_PATH_REGEX.test(value)) {
    return JSON.parse(replaceRequestPath(value, request));
  }
  return JSON.parse(value);
};

export const parseValueAsString = (
  value: string,
  request: FormRequest
): string => {
  const requestValue = parseValue(value, request);
  return formatRequestValueAsString(requestValue);
};
