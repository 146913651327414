import _ from "lodash";
import { createRef, FC, ReactElement } from "react";
import {
  Pagination as BootstrapPagination,
  Col,
  Dropdown,
  FormControl,
  InputGroup,
  Row,
} from "react-bootstrap";
import GlobalFilter, { GlobalFilterProps } from "../GlobalFilter";
import "./styles.scss";

export type PaginationProps = GlobalFilterProps & {
  className?: string;
  pageIndex: number;
  pageSize: number;
  canPreviousPage: boolean;
  canNextPage: boolean;
  pageCount: number;
  gotoPage: (pageIndex: number) => void;
  nextPage: () => void;
  previousPage: () => void;
  setPageSize: (pageSize: number) => void;
  totalSize: number;
  pageSizeOptions: number[];
  showGlobalFilter?: boolean;
};

const Pagination: FC<PaginationProps> = ({
  className,
  pageIndex,
  pageSize,
  canPreviousPage,
  canNextPage,
  pageCount,
  gotoPage,
  nextPage,
  previousPage,
  setPageSize,
  totalSize,
  pageSizeOptions,
  showGlobalFilter,
  ...globalFilterProps
}: PaginationProps): ReactElement => {
  const rowsPerPageRef = createRef<HTMLInputElement>();

  let pagination = (
    <>
      <BootstrapPagination.Prev
        onClick={() => previousPage()}
        disabled={!canPreviousPage}
      />
      <BootstrapPagination.First
        onClick={() => gotoPage(0)}
        active={pageIndex === 0}
      >
        1
      </BootstrapPagination.First>
    </>
  );
  if (pageCount > 1 && pageCount < 8) {
    pagination = (
      <>
        {pagination}
        <BootstrapPagination.Item
          onClick={() => gotoPage(1)}
          active={pageIndex === 1}
        >
          2
        </BootstrapPagination.Item>
      </>
    );
    if (pageCount > 2) {
      pagination = (
        <>
          {pagination}
          {_.range(2, pageCount).map((currentPageIndex) => (
            <BootstrapPagination.Item
              key={currentPageIndex}
              onClick={() => gotoPage(currentPageIndex)}
              active={currentPageIndex === pageIndex}
            >
              {currentPageIndex + 1}
            </BootstrapPagination.Item>
          ))}
        </>
      );
    }
  } else if (pageCount >= 8) {
    pagination = (
      <>
        {pagination}
        {pageIndex < 4 ? (
          <BootstrapPagination.Item
            onClick={() => gotoPage(1)}
            active={pageIndex === 1}
          >
            2
          </BootstrapPagination.Item>
        ) : (
          <BootstrapPagination.Ellipsis className="disabled" />
        )}
      </>
    );
    const startPageIndex = Math.max(2, Math.min(pageIndex - 1, pageCount - 5));
    const endPageIndexExclusive = Math.min(startPageIndex + 3, pageCount - 2);
    pagination = (
      <>
        {pagination}
        {_.range(startPageIndex, endPageIndexExclusive).map(
          (currentPageIndex) => (
            <BootstrapPagination.Item
              key={currentPageIndex}
              onClick={() => gotoPage(currentPageIndex)}
              active={currentPageIndex === pageIndex}
            >
              {currentPageIndex + 1}
            </BootstrapPagination.Item>
          )
        )}
        {pageIndex < pageCount - 4 ? (
          <BootstrapPagination.Ellipsis className="disabled" />
        ) : (
          <BootstrapPagination.Item
            onClick={() => gotoPage(pageCount - 2)}
            active={pageIndex === pageCount - 2}
          >
            {pageCount - 1}
          </BootstrapPagination.Item>
        )}
        <BootstrapPagination.Last
          onClick={() => gotoPage(pageCount - 1)}
          active={pageIndex === pageCount - 1}
        >
          {pageCount}
        </BootstrapPagination.Last>
      </>
    );
  }

  return (
    <Row className={`emory-pagination${className ? ` ${className}` : ""}`}>
      <Col className="d-inline-flex justify-content-between align-items-center">
        <InputGroup className="w-25" data-testid="pageSize-inputGroup">
          <InputGroup.Text>Rows per page</InputGroup.Text>
          <FormControl
            ref={rowsPerPageRef}
            type="text"
            defaultValue={pageSize}
            onBlur={({ target: { value } }) => setPageSize(parseInt(value, 10))}
          />
          <Dropdown align="end">
            <Dropdown.Toggle variant="outline-dark" />
            <Dropdown.Menu>
              {pageSizeOptions.map((size) => (
                <Dropdown.Item
                  key={size}
                  onClick={() => {
                    if (rowsPerPageRef.current) {
                      rowsPerPageRef.current.value = `${size}`;
                    }
                    setPageSize(size);
                  }}
                >
                  {size}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </InputGroup>
        <span className="text-muted text-nowrap mx-auto">
          {totalSize === 0 ? (
            "Showing 0 of 0 records"
          ) : (
            <>
              {`Showing ${pageIndex * pageSize + 1}-${Math.min(
                (pageIndex + 1) * pageSize,
                totalSize
              )} of ${totalSize} records`}
            </>
          )}
        </span>
        <div className="d-flex">
          {showGlobalFilter && (
            <GlobalFilter className="me-2" {...globalFilterProps} />
          )}
          <BootstrapPagination
            className="mb-0 ms-auto"
            data-testid="pagination-buttonGroup"
          >
            {pagination}
            <BootstrapPagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
          </BootstrapPagination>
        </div>
      </Col>
    </Row>
  );
};

Pagination.defaultProps = {
  className: undefined,
  showGlobalFilter: true,
};

export default Pagination;
