import { AppRouteProps } from "../../shared/src/models";
import RCRAStudentForm from "../pages/RCRAStudentForm";
import RCRASuccessPage from "../pages/RCRASuccessPage";
import RCRAPath from "./RCRAPath";

const RCRAAppRoutes: AppRouteProps[] = [
  {
    ...RCRAPath.RCRA,
    exact: false,
    render: () => <RCRAStudentForm />,
  },
  {
    ...RCRAPath.RCRASuccess,
    exact: true,
    render: () => <RCRASuccessPage />,
  },
];

export default RCRAAppRoutes;
