import BaseAppPath from "../../../modules/shared/src/modules/router/models/AppPath";

class AppPath extends BaseAppPath {
  public static readonly Home = new AppPath({
    id: "home",
    label: "Home",
    path: "/",
  });

  public static readonly PageNotFound = new AppPath({
    id: "page-not-found",
    label: "Page Not Found",
    path: "/*",
  });

  public static readonly ShibbolethLogin = new AppPath({
    id: "shibboleth-login",
    label: "Shibboleth Login",
    path: "/Shibboleth.sso/Login",
  });

  public static readonly ShibbolethLoginEuship = new AppPath({
    id: "shibboleth-login-euship",
    label: "Shibboleth Login",
    path: "/Shibboleth.sso/Login?target=/euship",
  });
}

export default AppPath;
