/* eslint-disable react/no-danger */
import DOMPurify from "dompurify";
import React, { ChangeEvent, FC, ReactElement } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import ManagedContent from "../../../../models/salesforce/ManagedContent";
import FormLabel from "../../../Form/components/FormLabel";

const TEMPORARY_ATTRIBUTE = "data-temp-href-target";

DOMPurify.addHook("beforeSanitizeAttributes", (node) => {
  if (node.tagName === "A") {
    if (!node.hasAttribute("target")) {
      node.setAttribute("target", "_self");
    }
    if (node.hasAttribute("target")) {
      node.setAttribute(TEMPORARY_ATTRIBUTE, node.getAttribute("target") ?? "");
    }
  }
});

DOMPurify.addHook("afterSanitizeAttributes", (node) => {
  if (node.tagName === "A" && node.hasAttribute(TEMPORARY_ATTRIBUTE)) {
    node.setAttribute("target", node.getAttribute(TEMPORARY_ATTRIBUTE) ?? "");
    node.removeAttribute(TEMPORARY_ATTRIBUTE);
    if (node.getAttribute("target") === "_blank") {
      node.setAttribute("rel", "noopener ");
    }
  }
});

export type ManagedContentFormProps = {
  id: string;
  managedContent: ManagedContent;
  setManagedContent: (
    getManagedContent: (
      previousManagedContent: ManagedContent
    ) => ManagedContent
  ) => void;
};

const ManagedContentForm: FC<ManagedContentFormProps> = ({
  id,
  managedContent,
  setManagedContent,
}: ManagedContentFormProps): ReactElement => {
  return (
    <>
      <fieldset id={id}>
        <Row className="mb-3">
          <Col>
            <Form.Label>Feature ID</Form.Label>
            <Form.Control
              id={`${id}-managed-content-form-feature-id`}
              type="text"
              value={managedContent.featureId ?? ""}
              onChange={({ target: { value } }) => {
                setManagedContent((previousManagedContent) => ({
                  ...previousManagedContent,
                  featureId: value,
                }));
              }}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Label>Name</Form.Label>
            <Form.Control
              id={`${id}-managed-content-form-name`}
              type="text"
              value={managedContent.name ?? ""}
              onChange={({ target: { value } }) => {
                setManagedContent((previousManagedContent) => ({
                  ...previousManagedContent,
                  name: value,
                }));
              }}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md="6">
            <FormLabel label="Content" />
            <Form.Control
              id={`${id}-managed-content-form-content`}
              name="content"
              as="textarea"
              rows={10}
              value={managedContent.content ?? ""}
              onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                const {
                  target: { value: content },
                } = event;
                setManagedContent((previousManagedContent) => {
                  return {
                    ...previousManagedContent,
                    content,
                  };
                });
              }}
            />
          </Col>
          <Col md="6">
            <FormLabel label="Preview" />
            <Card bg="light" text="dark">
              <Card.Body className="p-2">
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(managedContent?.content ?? ""),
                  }}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </fieldset>
    </>
  );
};

export default ManagedContentForm;
