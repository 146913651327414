import {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Button, Col, Container, Navbar, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import useFormByFeatureName from "../../../shared/src/hooks/useFormByFeatureName";
import ComponentProps from "../../../shared/src/models/ComponentProps";
import { QTMFormRequest } from "../../models/QTMFormRequest";
import { Form } from "../../../shared/src/components";
import useLoading from "../../../shared/src/hooks/useLoading";
import QTMContactService from "../../services/QTMContactService";
import {
  mapQTMFormRequestToQTMContact,
  QTMContact,
} from "../../models/salesforce/QTMContact";
import {
  getRequestByKey,
  setRequestByKey,
} from "../../../shared/src/components/Form";
import { ErrorResponse } from "../../../shared/src/models/ErrorResponse";
import useCurrentUser from "../../../../hooks/useCurrentUser";
import "./styles.scss";

type QTMSaveAction = "Update Request" | "Submit Request";

const QTM: FC = (): ReactElement => {
  const { form } = useFormByFeatureName("QTM");
  const [formContent, setFormContent] = useState<ComponentProps>();
  const [initialRequest, setInitialRequest] = useState<QTMFormRequest>({});
  const currentUser = useCurrentUser();
  const loading = useLoading();
  const ppid = useMemo(() => currentUser.ppid || "", [currentUser]);
  const requestKey = useMemo(() => `qtmRequest-${ppid}`, [ppid]);

  useEffect(() => {
    setFormContent(form);
  }, [form]);

  const refreshRequest = useCallback(async () => {
    if (ppid) {
      return loading(QTMContactService.getQTMContactByPpid(ppid, false)).then(
        async (response) => {
          const formRequest =
            await QTMContactService.mapQTMContactToQTMFormRequest(
              response as QTMContact
            );
          setInitialRequest((previousInitialRequest) => {
            return { ...previousInitialRequest, ...formRequest };
          });
          setRequestByKey(requestKey, formRequest);
        }
      );
    }
    return Promise.resolve();
  }, [loading, requestKey, ppid]);

  useEffect(() => {
    refreshRequest();
  }, [ppid, refreshRequest]);

  const handleSave = useCallback(
    async (action: QTMSaveAction) => {
      return loading(async () => {
        try {
          const formRequest = getRequestByKey(requestKey);
          setInitialRequest(formRequest);
          const request = mapQTMFormRequestToQTMContact(formRequest);
          const response = await QTMContactService.validateAndSave(
            request,
            action
          );
          if (response.every(({ success }) => success)) {
            toast.success("Form saved successfully");
            refreshRequest();
          }
          response.forEach((res) => {
            res.errors?.forEach((error) => {
              toast.error(`Failed to save form: ${error.message}`);
            });
          });
        } catch (error) {
          const axiosError = error as AxiosError;
          const errorResponse = axiosError.response?.data as ErrorResponse;
          const errors = errorResponse?.errors;
          if (errors && Array.isArray(errors)) {
            errors.forEach((err) => {
              toast.error(
                `Failed to save form: ${err.field ? `${err.field} - ` : ""}${
                  err.defaultMessage
                }`
              );
            });
          } else {
            toast.error(`Failed to save form with error: ${error}`);
          }
        }
      });
    },
    [loading, requestKey, refreshRequest]
  );
  return (
    <div className="qtm-student-onboarding-form">
      <Container className="mb-5">
        <Row>
          <Col>
            <Form
              content={formContent}
              initialRequest={initialRequest}
              requestKey={requestKey}
            />
          </Col>
        </Row>
      </Container>
      <Navbar
        fixed="bottom"
        className="d-flex justify-content-center border-top bg-white"
      >
        <Button variant="primary" onClick={() => handleSave("Update Request")}>
          Save Form for Later
        </Button>
        <Button
          className="ms-2"
          variant="success"
          onClick={() => handleSave("Submit Request")}
        >
          Submit
        </Button>
      </Navbar>
    </div>
  );
};
export default QTM;
