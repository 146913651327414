import _ from "lodash";
import { useCallback, useContext } from "react";
import { trackPromise } from "react-promise-tracker";
import { v4 as uuid } from "uuid";
import { LoadingContext } from "../../models/LoadingContext";
import useSafeAsync from "../useSafeAsync";

export type LoadingFn = <T = unknown>(
  getPromise: Promise<T> | (() => Promise<T>),
  onFinishLoading?: () => void
) => Promise<T | undefined>;

const useLoading = (): LoadingFn => {
  const safeAsync = useSafeAsync();
  const { setLoading } = useContext(LoadingContext);

  return useCallback<LoadingFn>(
    async <T = unknown>(
      getPromise: Promise<T> | (() => Promise<T>),
      onFinishLoading?: () => void
    ): Promise<T | undefined> => {
      const loadingId = uuid();
      setLoading(loadingId, true);
      let result;
      try {
        let promise = getPromise;
        if (_.isFunction(getPromise)) {
          promise = getPromise();
        }
        result = await safeAsync<T>(trackPromise(promise as Promise<T>));
      } finally {
        setLoading(loadingId, false);
      }
      if (onFinishLoading) {
        onFinishLoading();
      }
      return result;
    },
    [safeAsync, setLoading]
  );
};

export default useLoading;
