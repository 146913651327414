/* eslint-disable react/no-danger */
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DOMPurify from "dompurify";
import _ from "lodash";
import React, {
  FC,
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Anchor,
  Overlay,
  OverlayProps,
  Popover,
  PopoverProps,
  Tooltip,
} from "react-bootstrap";
import { v4 as uuid } from "uuid";
import "./styles.scss";

type Trigger = "hover" | "click" | "focus";

export type InfoIconProps = {
  info?: ReactNode;
  icon?: ReactNode;
  useWrapper?: boolean;
  wrapperType?: "tooltip" | "popover";
  overlayProps?: Omit<OverlayProps, "children" | "target">;
  popoverProps?: PopoverProps;
  trigger?: Trigger | Trigger[];
};

const InfoIcon: FC<InfoIconProps> = ({
  info,
  icon,
  useWrapper,
  wrapperType,
  overlayProps,
  popoverProps,
  trigger,
}: InfoIconProps): ReactElement => {
  const [show, setShow] = useState<boolean>(false);
  const ref = useRef(null);
  const [target, setTarget] = useState<HTMLElement | null>(null);

  const triggers: Trigger[] = useMemo(() => {
    const currentTriggers = (
      Array.isArray(trigger) ? trigger : [trigger]
    ).filter((x) => !_.isUndefined(x)) as Trigger[];
    if (currentTriggers.length === 0) {
      return ["click"];
    }
    return currentTriggers;
  }, [trigger]);

  const showOverlay = useCallback((showTarget: HTMLElement) => {
    setShow(true);
    setTarget(showTarget);
  }, []);

  if (!info) {
    return <></>;
  }

  return (
    <span className="info-icon" ref={ref}>
      <Anchor
        onClick={(event) => {
          if (triggers.includes("click")) {
            showOverlay(event.target as HTMLElement);
          }
        }}
        onMouseOver={(event) => {
          if (triggers.includes("hover")) {
            event.currentTarget.focus();
          }
        }}
        onFocus={(event) => {
          if (triggers.includes("focus")) {
            showOverlay(event.target);
          }
        }}
        onBlur={() => {
          setShow(false);
        }}
      >
        {icon ?? (
          <FontAwesomeIcon icon={faInfoCircle} className="ms-1 text-primary" />
        )}
      </Anchor>
      {useWrapper ? (
        <>
          {wrapperType === "popover" && (
            <Overlay
              placement="bottom"
              transition={false}
              {...(overlayProps ?? {})}
              show={show}
              target={target}
              container={ref}
            >
              <Popover {...popoverProps}>
                <Popover.Body>
                  {_.isString(info) ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(info),
                      }}
                    />
                  ) : (
                    <>{info}</>
                  )}
                </Popover.Body>
              </Popover>
            </Overlay>
          )}
          {wrapperType === "tooltip" && (
            <Overlay
              placement="right"
              transition={false}
              {...(overlayProps ?? {})}
              show={show}
              target={target}
              container={ref}
            >
              <Tooltip id={uuid()}>
                <>{info}</>
              </Tooltip>
            </Overlay>
          )}
        </>
      ) : (
        <Overlay
          placement="right"
          transition={false}
          {...(overlayProps ?? {})}
          show={show}
          target={target}
          container={ref}
        >
          <>{info}</>
        </Overlay>
      )}
    </span>
  );
};

InfoIcon.defaultProps = {
  info: undefined,
  icon: undefined,
  useWrapper: true,
  wrapperType: "popover",
  overlayProps: {},
  popoverProps: {},
  trigger: "click",
};

export default InfoIcon;
