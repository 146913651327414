import React, { FC, ReactElement } from "react";
import {
  Col as BootstrapCol,
  ColProps as BootstrapColProps,
} from "react-bootstrap";
import { ComponentProps } from "../../models";
import { renderContent } from "../Component/ComponentUtil";

export interface ColProps extends BootstrapColProps {
  content?: ComponentProps | ComponentProps[];
}

const Col: FC<ColProps> = ({ content, ...props }: ColProps): ReactElement => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <BootstrapCol {...props}>
      <>{renderContent(content)}</>
    </BootstrapCol>
  );
};

Col.defaultProps = {
  content: undefined,
};

export default Col;
