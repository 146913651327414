import { FC, MouseEventHandler } from "react";
import { ButtonProps } from "react-bootstrap";
import Modal, { ModalProps } from "../Modal";

export type ConfirmationModalProps = Omit<
  ModalProps,
  "submitButtonProps" | "submitButtonText" | "onSubmit"
> & {
  confirmButtonProps?: ButtonProps;
  confirmButtonText?: string;
  onConfirm: MouseEventHandler<HTMLButtonElement>;
};

const ConfirmationModal: FC<ConfirmationModalProps> = ({
  show,
  title,
  body,
  onCancel,
  confirmButtonProps,
  confirmButtonText,
  onConfirm,
  modalContentStyles,
  ...modalProps
}: ConfirmationModalProps) => {
  return (
    <Modal
      show={show}
      title={title}
      body={body}
      submitButtonProps={confirmButtonProps}
      submitButtonText={confirmButtonText}
      onCancel={onCancel}
      onSubmit={onConfirm}
      modalContentStyles={{
        width: "50%",
        ...modalContentStyles,
      }}
      {...modalProps}
    />
  );
};

ConfirmationModal.defaultProps = {
  confirmButtonProps: {},
  confirmButtonText: "Confirm",
};

export default ConfirmationModal;
