import AppHeaderLink, {
  AppHeaderLinkType,
} from "../../shared/src/components/AppHeader/AppHeaderLink";
import AppRouteProps from "../../shared/src/models/AppRouteProps";
import QTM from "../pages/QTM";
import QTMPath from "./QTMPath";

export const QTMAppHeaderLinks: AppHeaderLink[] = [
  {
    ...QTMPath.QTM,
    type: AppHeaderLinkType.LINK,
  },
];

export const QTMAppRoutes: AppRouteProps[] = [
  {
    ...QTMPath.QTM,
    exact: false,
    render: () => <QTM />,
  },
];

export function getQTMAppHeaderLinks(isStudent: boolean): AppHeaderLink[] {
  const newLinks = [];
  if (isStudent) {
    newLinks.push(...QTMAppHeaderLinks);
  }
  return newLinks;
}
