/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, ReactElement, ReactNode } from "react";
import { Alert } from "react-bootstrap";
import SalesforceManagedContent from "../../models/salesforce/ManagedContent";
import ManagedContentFromNameAndFeatureName from "./components/ManagedContentFromNameAndFeatureName";
import ManagedContentFromSalesforceObject from "./components/ManagedContentFromSalesforceObject";
import { EditableManagedContentProps } from "./models/EditableManagedContentProps";

export type ManagedContentProps = EditableManagedContentProps & {
  managedContent?: SalesforceManagedContent;
  name?: string;
  featureName?: string;
  as?: string;
  className?: string;
  editWrapperClassName?: string;
  render?: (content: string | undefined) => ReactNode;
  params?: Record<string, string | undefined>;
};

const ManagedContent: FC<ManagedContentProps> = ({
  managedContent,
  featureName,
  name,
  ...props
}: ManagedContentProps): ReactElement => {
  if (managedContent) {
    return (
      <ManagedContentFromSalesforceObject {...props} {...managedContent} />
    );
  }

  if (featureName && name) {
    return (
      <ManagedContentFromNameAndFeatureName
        {...props}
        featureName={featureName}
        name={name}
      />
    );
  }

  return (
    <Alert variant="danger">
      Invalid use of ManagedContent component: name={name}, featureName=
      {featureName}, managedContent={managedContent}
    </Alert>
  );
};

ManagedContent.defaultProps = {
  managedContent: undefined,
  name: undefined,
  featureName: undefined,
  as: "span",
  className: undefined,
  editWrapperClassName: undefined,
  render: undefined,
  params: undefined,
};

export default ManagedContent;
