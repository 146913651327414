import AppPath from "../../../components/App/models/AppPath";
import FeatureName from "../../../components/App/models/FeatureName";
import ManagedContent from "../../shared/src/components/ManagedContent";
import ManagedContentName from "./ManagedContentName";

class MOCPath extends AppPath {
  public static readonly MOC = new AppPath({
    id: "pim",
    label: (
      <ManagedContent
        name={ManagedContentName.ProgramsInvolvingMinorsLabelForm}
        featureName={FeatureName.MinorsOnCampusRegistrationForm}
      />
    ),
    path: "/pim/form/:id?",
  });

  public static readonly MOCSubmissions = new AppPath({
    id: "pim-submissions",
    label: (
      <ManagedContent
        name={ManagedContentName.ProgramsInvolvingMinorsLabelRegistration}
        featureName={FeatureName.MinorsOnCampusRegistrationForm}
      />
    ),
    path: "/pim/submitter-dashboard",
  });

  public static readonly MOCSubmissionsApprover = new AppPath({
    id: "pim-approver",
    label: (
      <ManagedContent
        name={ManagedContentName.ProgramsInvolvingMinorsLabelApprovers}
        featureName={FeatureName.MinorsOnCampusRegistrationForm}
      />
    ),
    path: "/pim/approver-dashboard",
  });
}

export default MOCPath;
