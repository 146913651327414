import { AxiosError } from "axios";

export const isShibbolethTimeoutError = (
  error: Record<string, unknown>
): boolean => {
  if (error instanceof AxiosError) {
    const axiosError = error as AxiosError;
    return (
      axiosError.code === "ERR_NETWORK" &&
      axiosError.name === "AxiosError" &&
      axiosError.message === "Network Error"
    );
  }
  // https://lifesaver.codes/answer/need-some-advice-about-handling-302-redirects-from-ajax
  return typeof error?.response === "undefined";
};

export const goToShibbolethLoginPage = (): void => {
  window.location.href = `${window.location.origin}/Shibboleth.sso/Login`;
};

export const handleShibbolethTimeoutError = (
  error: Record<string, unknown>
): void => {
  if (isShibbolethTimeoutError(error)) {
    goToShibbolethLoginPage();
  }
};
