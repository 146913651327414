/* eslint-disable react/no-danger */
import { sanitize } from "dompurify";
import React, { FC, ReactElement } from "react";
import { Variant } from "react-bootstrap/esm/types";

export type FormValidationProps = {
  message: string | ReactElement;
  variant?: Variant;
};

const FormValidation: FC<FormValidationProps> = ({
  message,
  variant,
}: FormValidationProps): ReactElement => {
  if (React.isValidElement(message)) {
    return message;
  }

  return (
    <div className="form-validation">
      {React.isValidElement(message) ? (
        <div className={`text-${variant}`}>
          <>{message}</>
        </div>
      ) : (
        <div
          className={`text-${variant}`}
          dangerouslySetInnerHTML={{
            __html: sanitize(message as string),
          }}
        />
      )}
    </div>
  );
};

FormValidation.defaultProps = {
  variant: "danger",
};

export default FormValidation;
