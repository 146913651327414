import { FC, ReactElement, useEffect, useMemo, useState } from "react";
import { Col, Container, Nav, Row } from "react-bootstrap";
import "./styles.scss";
import FormBreadcrumb from "../FormBreadcrumb";
import ComponentProps from "../../../../models/ComponentProps";
import useFormByFeatureName from "../../../../hooks/useFormByFeatureName";
import SalesforceObject from "../../../../models/salesforce/SalesforceObject";
import { FormRequest } from "../../../../components/Form/models/FormRequest";
import {
  getSectionColumns,
  getSidebarNavLinks,
} from "../../../../utils/FormUtil";
import Form from "../../../../components/Form";
import AppPath from "../../../router/models/AppPath";

export type FormDisplayerProps<T extends SalesforceObject> = {
  initialRequest: T;
  requestKey: string;
  formFeatureName: string;
  formPagePaths: AppPath[];
  showSidebar?: boolean;
  readonly?: boolean;
  onSetRequestByKey?: (
    previousRequest: FormRequest,
    updatedRequest: FormRequest
  ) => FormRequest;
};

const FormDisplayer: FC<FormDisplayerProps<SalesforceObject>> = ({
  initialRequest,
  requestKey,
  formFeatureName,
  formPagePaths,
  showSidebar,
  readonly,
  onSetRequestByKey,
}): ReactElement => {
  const { form } = useFormByFeatureName(formFeatureName);
  const [formContent, setFormContent] = useState<ComponentProps>();

  useEffect(() => {
    setFormContent(form);
  }, [form]);

  const sectionColumns = useMemo(
    () => getSectionColumns(formContent),
    [formContent]
  );
  const sidebarNavLinks = useMemo(() => {
    return getSidebarNavLinks(sectionColumns);
  }, [sectionColumns]);
  return (
    <div className="form-displayer-wrapper">
      <Container fluid={showSidebar} className="mb-5">
        <Row>
          {showSidebar && (
            <Col xs={2} className="sidebar-wrapper">
              <Nav
                className="d-block bg-light sidebar col-2"
                defaultActiveKey="formTitle"
              >
                {sidebarNavLinks
                  .filter(({ sectionColumn: { sectionRowId } }) => {
                    if (sectionRowId) {
                      return true;
                    }
                    return false;
                  })
                  .map(({ level, id, text }) => (
                    <Nav.Link
                      key={id}
                      eventKey={id}
                      onClick={() =>
                        document.getElementById(id)?.scrollIntoView({
                          behavior: "smooth",
                          block: "center",
                        })
                      }
                      style={{
                        marginLeft: `${(level - 1) * 20}px`,
                      }}
                    >
                      {text}
                    </Nav.Link>
                  ))}
              </Nav>
            </Col>
          )}
          <Col xs={showSidebar ? 10 : 12} className="page-content-wrapper">
            {formPagePaths && <FormBreadcrumb paths={formPagePaths} />}
            <Form
              content={formContent}
              initialRequest={initialRequest}
              requestKey={requestKey}
              disabled={readonly}
              onSetRequestByKey={onSetRequestByKey}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

FormDisplayer.defaultProps = {
  showSidebar: false,
  readonly: false,
  onSetRequestByKey: undefined,
};

export default FormDisplayer;
