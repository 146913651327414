import DOMPurify from "dompurify";
import _ from "lodash";
import React, { ReactElement } from "react";
import { v4 as uuid } from "uuid";
import Component from ".";
import { ComponentProps } from "../../models";

type HasId = {
  id: string;
};

type HasOtherProps = {
  otherProps: string;
};

const WHITE_SPACE_REGEX = /\s+/;

export function removeClassName(
  currentClassNames: string,
  className: string
): string {
  if (currentClassNames && className) {
    const classNames = currentClassNames.split(WHITE_SPACE_REGEX);
    if (classNames.includes(className)) {
      return classNames.filter((x) => x !== className).join(" ");
    }
  }
  return currentClassNames;
}

export function removeClassNames(
  currentClassNames: string,
  classNames: string[]
): string {
  if (currentClassNames && classNames) {
    const newClassNames = currentClassNames.split(WHITE_SPACE_REGEX);
    return newClassNames.filter((x) => !classNames.includes(x)).join(" ");
  }
  return currentClassNames;
}

export function addClassName(
  currentClassNames: string,
  className: string
): string {
  if (!currentClassNames) {
    return className;
  }
  if (className) {
    const classNames = currentClassNames.split(WHITE_SPACE_REGEX);
    if (!classNames.includes(className)) {
      classNames.push(className);
      return classNames.join(" ");
    }
  }
  return currentClassNames;
}

export function addClassNames(
  currentClassNames: string,
  classNames: string[]
): string {
  if (!currentClassNames) {
    return classNames.join(" ");
  }
  if (classNames?.length) {
    const newClassNames = currentClassNames.split(WHITE_SPACE_REGEX);
    newClassNames.push(...classNames.filter((x) => !newClassNames.includes(x)));
    return newClassNames.join(" ");
  }
  return currentClassNames;
}

export function setPropByIds(
  ids: string[],
  prop: string,
  value?: unknown,
  optionalComponentProps?: ComponentProps,
  getPropValue?: (currentValue: unknown) => unknown
): ComponentProps {
  const componentProps = optionalComponentProps as ComponentProps;

  let newComponentProps;

  if (ids.includes((componentProps?.props as HasId)?.id)) {
    const { props, ...otherComponentProps } = componentProps;
    newComponentProps = {
      ...(otherComponentProps || {}),
      props: {
        ...((props as Record<string, unknown>) || {}),
        [prop]: getPropValue
          ? getPropValue((props as Record<string, unknown>)[prop])
          : value,
      },
    };
    return newComponentProps;
  }

  const { otherProps } = (componentProps?.props as HasOtherProps) || {};
  if (
    otherProps &&
    _.isString(otherProps) &&
    ids.some((id) => otherProps.indexOf(`"id":"${id}"`))
  ) {
    const { props, ...otherComponentProps } = componentProps;
    newComponentProps = {
      ...(otherComponentProps || {}),
      props: {
        ...((props as Record<string, unknown>) || {}),
        otherProps: JSON.stringify(
          (JSON.parse(otherProps) as Record<string, unknown>[]).map(
            (parsedOtherProps) => {
              if (ids.includes((parsedOtherProps as HasId).id)) {
                return {
                  ...parsedOtherProps,
                  [prop]: getPropValue
                    ? getPropValue(parsedOtherProps[prop])
                    : value,
                };
              }
              return parsedOtherProps;
            }
          )
        ),
      },
    };
    return newComponentProps;
  }

  if (!componentProps?.content) {
    return componentProps;
  }

  if (
    Array.isArray(componentProps?.content) &&
    (componentProps.content || []).length
  ) {
    if (typeof componentProps.content[0] === "string") {
      return componentProps;
    }
    newComponentProps = {
      ...componentProps,
      content: (componentProps.content as ComponentProps[]).map((x) => {
        return { ...setPropByIds(ids, prop, value, x, getPropValue) };
      }),
    };
    return newComponentProps;
  }

  if (typeof componentProps.content === "string") {
    return componentProps;
  }

  return {
    ...(componentProps || {}),
    content: setPropByIds(
      ids,
      prop,
      value,
      componentProps.content as ComponentProps,
      getPropValue
    ),
  };
}

export function renderContent(
  content?: ComponentProps | ComponentProps[] | string
): ReactElement {
  if (content === undefined) {
    return <></>;
  }

  if (typeof content === "string") {
    return (
      // eslint-disable-next-line react/no-danger
      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }} />
    );
  }

  if (Array.isArray(content)) {
    return (
      <>
        {content.map((props) => {
          if (!props) {
            return undefined;
          }
          return (
            <Component
              key={(props.props as HasId)?.id || uuid()}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
            />
          );
        })}
      </>
    );
  }

  return renderContent([content]);
}
