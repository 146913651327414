import SalesforceObject from "../../../shared/src/models/salesforce/SalesforceObject";
import type { RCRAFormRequest } from "../RCRAFormRequest";

export type RCRASubmission = SalesforceObject &
  Partial<{
    alternateContactEmail: string;
    alternateContactFirstName: string;
    alternateContactLastName: string;
    alternateContactPhone: string;
    currentAction: string;
    gradeLevelOfMinor: string;
    minorCertification: boolean;
    minorCertificationDate: string;
    minorDateOfBirth: string;
    minorEmail: string;
    minorFirstName: string;
    minorLastName: string;
    parentCertification: boolean;
    parentCertificationDate: string;
    parentEmail: string;
    parentFirstName: string;
    parentLastName: string;
    parentPhone: string;
    schoolCertification: boolean;
    schoolCertificationDate: string;
    schoolEmail: string;
    schoolOfMinor: string;
    schoolOfficialName: string;
    schoolOfficialTitle: string;
    schoolProvidedInformation: string;
  }>;

export const mapRCRAFormRequestToRCRASubmission = (
  request: RCRAFormRequest
): RCRASubmission => {
  return {
    ...request,
    gradeLevelOfMinor: request.gradeLevelOfMinor?.value,
  };
};
