import { createContext } from "react";

export type LoadingContextProps = {
  isLoading: boolean;
  setLoading: (id: string, loading: boolean) => void;
};

export const LoadingContext = createContext<LoadingContextProps>({
  isLoading: false,
  setLoading: () => {},
});
