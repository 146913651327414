import { FC, ReactElement, useContext, useMemo } from "react";
import { FormContext } from "../../../../models/FormContext";
import FormSearchSelect, { FormSearchSelectProps } from "../FormSearchSelect";

export type FormMultiSelectProps = FormSearchSelectProps;

const FormMultiSelect: FC<FormMultiSelectProps> = (
  initialProps: FormMultiSelectProps
): ReactElement => {
  const { disabled: disabledByContext } = useContext(FormContext);

  const props = useMemo<FormMultiSelectProps>(() => {
    const { disabled, styles } = initialProps ?? {};
    const isDisabled = disabledByContext || disabled;
    const newStyles = { ...styles };
    if (isDisabled) {
      // https://github.com/JedWatson/react-select/issues/1503#issuecomment-500860503
      newStyles.multiValueRemove = (base) => ({ ...base, display: "none" });
    }
    return {
      ...initialProps,
      styles: newStyles,
    };
  }, [disabledByContext, initialProps]);

  return <FormSearchSelect {...props} isMulti />;
};

export default FormMultiSelect;
