import SalesforceResponse from "../../../shared/src/models/salesforce/SalesforceResponse";
import { BaseService } from "../../../shared/src/services";
import { getDefaultAxiosInstance } from "../../../shared/src/services/BaseService/AxiosService";
import { RCRAFormRequest } from "../../models/RCRAFormRequest";
import { RCRASubmission } from "../../models/salesforce/RCRASubmission";

export type RCRASubmissionSaveAction =
  | "Create New Request"
  | "Update Request"
  | "Submit"
  | "EHSO Approval"
  | "Export Control Approval"
  | "IACUC Approval"
  | "Minor Approval"
  | "Parent Approval"
  | "IRB Approval"
  | "RCRA Approval"
  | "Research Security Approval"
  | "School Approval";

class MyEmoryRCRASubmissionService extends BaseService {
  protected static readonly BASE_PATH = "/api/myemory/v0/rcra-submissions";

  constructor(public readonly basePath: string) {
    super(getDefaultAxiosInstance());
  }

  public static async getRCRASubmissionByIdTokenAndAction(
    id: string,
    token: string,
    action: RCRASubmissionSaveAction
  ): Promise<RCRASubmission> {
    const response = await super.get<RCRASubmission>({
      url: `${this.BASE_PATH}/approvals/${id}`,
      config: {
        cache: false,
        params: { token, action },
      },
    });
    return response?.data || {};
  }

  public static async validateAndSave<RCRASubmission>(
    submission: RCRASubmission,
    action: RCRASubmissionSaveAction
  ): Promise<SalesforceResponse[]> {
    const response = await super.post<SalesforceResponse[], RCRASubmission>({
      url: `${this.BASE_PATH}`,
      data: submission,
      config: {
        cache: false,
        params: { action, skipValidation: false },
      },
    });
    return response?.data || [];
  }

  public static async mapRCRASubmissionToRCRAFormRequest(
    request: RCRASubmission
  ): Promise<RCRAFormRequest> {
    return {
      ...request,
      gradeLevelOfMinor: {
        label: request.gradeLevelOfMinor,
        value: request.gradeLevelOfMinor,
      },
    } as RCRAFormRequest;
  }
}

export default MyEmoryRCRASubmissionService;
