import AppPath from "../../../components/App/models/AppPath";

class EUSHIPPath extends AppPath {
  public static readonly EUSHIP = new AppPath({
    id: "euship",
    label: "Student Health Insurance Inquiry",
    path: "/euship",
  });
}
export default EUSHIPPath;
