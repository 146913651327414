const ContentManagementMode = {
  ON: "ON",
  OFF: "OFF",
};

class ContentManagementUtil {
  private static readonly contentManagementModeCacheKey =
    "contentManagementMode";

  public static isContentManagementModeEnabled(): boolean {
    return (
      localStorage.getItem(
        ContentManagementUtil.contentManagementModeCacheKey
      ) === ContentManagementMode.ON
    );
  }

  public static setEnableContentManagementMode(
    enableContentManagementMode: boolean,
    reload = true
  ): void {
    if (enableContentManagementMode) {
      localStorage.setItem(
        ContentManagementUtil.contentManagementModeCacheKey,
        ContentManagementMode.ON
      );
    } else {
      localStorage.removeItem(
        ContentManagementUtil.contentManagementModeCacheKey
      );
    }
    if (reload) {
      window.location.reload();
    }
  }
}

export default ContentManagementUtil;
