import AppRouteProps from "../../models/AppRouteProps";

export enum AppHeaderLinkType {
  BUTTON = "button",
  LINK = "link",
  TEXT = "text",
  DROPDOWN = "dropdown",
  DROPDOWN_DIVIDER = "dropdown-divider",
  DROPDOWN_ITEM = "dropdown-item",
  DROPDOWN_TEXT = "dropdown-text",
}

export function getAppHeaderLinkByType(
  route: AppRouteProps,
  type: AppHeaderLinkType
): AppHeaderLink {
  return {
    ...route,
    type,
  } as AppHeaderLink;
}

interface AppHeaderLink extends AppRouteProps {
  type: AppHeaderLinkType;
  childLinks?: AppHeaderLink[];
  onClick?: () => void;
}

export default AppHeaderLink;
