class FormRuleAction {
  private static ALL_VALUES: FormRuleAction[] = [];

  public static readonly SHOW = new FormRuleAction("SHOW", "");

  public static readonly HIDE = new FormRuleAction("HIDE", "");

  public static readonly SET_PROP = new FormRuleAction("SET_PROP(", ")");

  public static readonly SET_VALUE = new FormRuleAction("SET_VALUE(", ")");

  constructor(public readonly prefix: string, public readonly suffix: string) {
    FormRuleAction.ALL_VALUES.push(this);
  }

  public static values(): FormRuleAction[] {
    return FormRuleAction.ALL_VALUES;
  }

  public isMatch(action: string): boolean {
    return action.startsWith(this.prefix) && action.endsWith(this.suffix);
  }
}

export default FormRuleAction;
