/* eslint-disable no-param-reassign */
import _ from "lodash";

export const getModifiedFieldNames = (
  obj1: Record<string, unknown>,
  obj2: Record<string, unknown>
): string[] => {
  const difference = _.transform(
    obj2,
    (result: Record<string, unknown>, value, key) => {
      if (!_.has(obj1, key)) {
        result[key] = value;
      }
      if (!_.isEqual(value, obj1[key])) {
        result[key] = value;
      }
    }
  );
  // map removed fields to undefined
  _.forOwn(obj1, (_value, key) => {
    if (!_.has(obj2, key)) {
      difference[key] = undefined;
    }
  });
  return _.keys(difference);
};

export default {
  getModifiedFieldNames,
};
