import ManagedContent from "../../../models/salesforce/ManagedContent";
import SalesforceResponse from "../../../models/salesforce/SalesforceResponse";
import FormSalesforceObjectService from "../FormSalesforceObjectService";

class ManagedContentService extends FormSalesforceObjectService {
  private static readonly OBJECT_NAME = "managed-contents";

  /**
   * Get managed content by id
   * @param managedContentId
   * @returns managed content
   */
  public static async getManagedContentById(
    managedContentId: string
  ): Promise<ManagedContent> {
    return super.findById<ManagedContent>(this.OBJECT_NAME, managedContentId);
  }

  /**
   * Get managed content by name and featureName
   * @param name
   * @param featureName
   * @returns managed content
   */
  public static async getManagedContentByNameAndFeatureName(
    name: string,
    featureName: string
  ): Promise<ManagedContent> {
    const response = await super.get<ManagedContent | undefined>({
      url: `${this.BASE_PATH}/${this.OBJECT_NAME}/names/${name}/feature-names/${featureName}`,
    });
    return response?.data || {};
  }

  /**
   * Save managed content
   * @param managedContent
   * @returns salesforce response
   */
  public static async saveManagedContent(
    managedContent: ManagedContent
  ): Promise<SalesforceResponse> {
    const response = await super.save<ManagedContent>(this.OBJECT_NAME, [
      managedContent,
    ]);
    return response[0];
  }
}

export default ManagedContentService;
