import { createContext } from "react";
import { FormRequest, RequestStateProps } from "./FormRequest";
import { ValidationError } from "../../../models/ErrorResponse";
import FormOption from "../components/FormInput/models/FormOption";

export type FormContextProps<T = FormRequest> = RequestStateProps<T> & {
  disabled: boolean;
  validateFields?: (
    request: FormRequest,
    ...fieldNames: string[]
  ) => Promise<Record<string, ValidationError[]>>;
  alwaysValidateFields?: boolean;
  isPrintMode?: boolean;
  optionsByFieldNameMap?: Record<string, FormOption<unknown>[]>;
};

export const FormContext = createContext<FormContextProps>({
  initialRequest: {},
  setRequest: () => {},
  disabled: false,
  alwaysValidateFields: false,
  isPrintMode: false,
  optionsByFieldNameMap: {},
});
