import { FormRequest } from "../../components/Form/models/FormRequest";
import FormRuleOperation from "./FormRuleOperation";

export const parseDependentFieldNamesFromRuleExpression = (
  ruleExpression: string
): string[] => {
  const operation = FormRuleOperation.values().find((x) =>
    x.isMatch(ruleExpression)
  );
  if (operation) {
    return operation.parseDependentFieldNames(ruleExpression);
  }
  return [];
};

export const parseDependentFieldNamesFromRule = (
  rule: string | undefined
): string[] => {
  if (!rule) {
    return [];
  }
  const orRuleExpressions = rule.split(" OR ");
  return orRuleExpressions.flatMap((orRuleExpression) => {
    const andRuleExpressions = orRuleExpression.split(" AND ");
    return andRuleExpressions.flatMap((ruleExpression) =>
      parseDependentFieldNamesFromRuleExpression(ruleExpression)
    );
  });
};

export const evaluateRuleExpression = (
  ruleExpression: string,
  request: FormRequest
): boolean => {
  const operation = FormRuleOperation.values().find((x) =>
    x.isMatch(ruleExpression)
  );
  if (operation) {
    return operation.evaluate(ruleExpression, request);
  }
  return false;
};

export const evaluateRule = (
  rule: string | undefined,
  request: FormRequest,
  ruleEvaluationCache: Map<string, boolean>
): boolean => {
  if (!rule) {
    return false;
  }

  if (ruleEvaluationCache.has(rule)) {
    return ruleEvaluationCache.get(rule) || false;
  }

  const orRuleExpressions = rule.split(" OR ");

  const ruleResult = orRuleExpressions.some((orRuleExpression) => {
    if (ruleEvaluationCache.has(orRuleExpression)) {
      return ruleEvaluationCache.get(orRuleExpression) || false;
    }

    const andRuleExpressions = orRuleExpression.split(" AND ");

    const orRuleExpressionResult = andRuleExpressions.every(
      (ruleExpression) => {
        if (ruleEvaluationCache.has(ruleExpression)) {
          return ruleEvaluationCache.get(ruleExpression) || false;
        }

        const ruleExpressionResult = evaluateRuleExpression(
          ruleExpression,
          request
        );

        ruleEvaluationCache.set(ruleExpression, ruleExpressionResult);

        return ruleExpressionResult;
      }
    );

    ruleEvaluationCache.set(orRuleExpression, orRuleExpressionResult);

    return orRuleExpressionResult;
  });

  ruleEvaluationCache.set(rule, ruleResult);

  return ruleResult;
};
