import MyEmoryContact from "../../../../models/salesforce/MyEmoryContact";
import { formOptionsToSalesforcePicklistString } from "../../../shared/src/components/Form/components/FormInput/models/FormOption";
import Account from "../../../shared/src/models/salesforce/Account";
import SalesforceObject from "../../../shared/src/models/salesforce/SalesforceObject";
import type { QTMFormRequest } from "../QTMFormRequest";
import type { ProgramPlan } from "./ProgramPlan";

export type QTMContact = SalesforceObject &
  MyEmoryContact &
  Partial<{
    academicLevel: string;
    advisorDeclareDate: string;
    areaOfIndustryInterest: string;
    communityAffiliations: string;
    degreeId: string;
    degree: Account;
    doubleMajor: string;
    doubleMajorNotListed: string;
    doubleMajorStudentProvided: string;
    homePhone: string;
    identityAffiliations: string;
    interestedInAmbassadorship: string;
    interestedInInternship: string;
    internshipEndDate: string;
    internshipStartDate: string;
    linkedInUsername: string;
    nonEmoryEmail: string;
    otherPronouns: string;
    oxfordContinuee: string;
    planTrackId: string;
    planTrack: ProgramPlan;
    primaryPlanId: string;
    primaryPlan: ProgramPlan;
    priorInternship: string;
    priorInternshipPlace: string;
    priorInternshipSupervisor: string;
    pronouns: string;
    secondaryPlanId: string;
    secondaryPlan: ProgramPlan;
    studentStatus: string;
    submitted: string;
    termToGraduate: string;
    yearToGraduate: string;
  }>;

export const mapQTMFormRequestToQTMContact = (
  request: QTMFormRequest
): QTMContact => {
  return {
    ...request,
    academicLevel: request.academicLevel?.value,
    areaOfIndustryInterest: formOptionsToSalesforcePicklistString(
      request.areaOfIndustryInterest
    ),
    communityAffiliations: formOptionsToSalesforcePicklistString(
      request.communityAffiliations
    ),
    identityAffiliations: formOptionsToSalesforcePicklistString(
      request.identityAffiliations
    ),
    planTrackId: request.planTrack?.value?.id,
    planTrack: request.planTrack?.value,
    primaryPlanId: request.primaryPlan?.value?.id,
    primaryPlan: request.primaryPlan?.value,
    pronouns: request.pronouns?.value,
    secondaryPlanId: request.secondaryPlan?.value?.id,
    secondaryPlan: request.secondaryPlan?.value,
    studentStatus: request.studentStatus?.value,
    termToGraduate: request.termToGraduate?.value,
    yearToGraduate: request.yearToGraduate?.value,
  };
};

export const QTMContactFields = {
  AFFINITY_GROUPS: "QTM_Affinity_Groups__c",
  AREA_OF_INDUSTRY_INTEREST: "QTM_Area_of_Industry_Interest__c",
  COMMUNITY_AFFILIATIONS: "QTM_Community_Affiliations__c",
  IDENTITY_AFFILIATIONS: "QTM_Identity_Affiliations__c",
};
